import React, { useEffect } from 'react';
import cn from 'classnames';

function ClutchWidget({ className, type = '1', height = '40' }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    script.type = 'text/javascript';
    script.onload = () => {
      window.CLUTCHCO.loaded = true;
      window.CLUTCHCO.Init();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <span
        className={cn(className, 'clutch-widget')}
        data-url="https://widget.clutch.co"
        data-widget-type={type}
        data-height={height}
        data-clutchcompany-id="33493"
      ></span>
    </>
  );
}

export default ClutchWidget;
