import React, { useMemo } from 'react';
import cn from 'classnames';
import { upperFirst } from 'utils';

import * as styles from './Tag.module.scss';

const Tag = React.forwardRef(
  ({ mods, theme = 'rectangular', children, className, active, onClick, ...rest }, ref) => {
    const composeMods = useMemo(() => {
      if (Array.isArray(mods)) {
        return mods.map(m => styles[m]);
      } else {
        return [styles[mods]];
      }
    }, [mods]);

    const Wrapper = onClick ? 'button' : 'div';

    return (
      <Wrapper
        ref={ref}
        className={cn(
          styles.tag,
          className,
          theme && styles[`theme${upperFirst(theme)}`],
          active && styles.active,
          ...composeMods,
        )}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Wrapper>
    );
  },
);

Tag.displayName = 'Tag';

export default Tag;
