import React from 'react';
import cn from 'classnames';
import { upperFirst } from 'utils';
import Icon from '../Icon';

import * as styles from './CloseButton.module.scss';

export default function CloseButton({ className, onClose, icon, theme, size, iconName = 'close' }) {
  return (
    <button
      className={cn(
        icon ? styles.closeIcon : styles.close,
        className,
        theme && styles[`theme${upperFirst(theme)}`],
        size && styles[`size${upperFirst(size)}`],
      )}
      onClick={onClose}
      aria-label='Close'
    >
      {icon && <Icon name={iconName} />}
    </button>
  );
}
