import React from 'react';
import cn from 'classnames';

import { Tag } from 'components/UI';
import { useHorizontalScroll } from 'utils/hooks';

import * as styles from './Tags.module.scss';

export default function Tags({ tags, active, handleClick, theme, className, withScroll = true }) {
  const { scrollRef } = useHorizontalScroll();
  const isDarkTheme = theme === 'dark';

  return (
    <div className={cn(withScroll && styles.container, className)} ref={scrollRef}>
      {tags.map(({ name, type }) => {
        const isActive = active === type;

        return (
          <Tag
            className={styles.tag}
            key={name}
            active={isActive}
            disabled={isActive}
            onClick={() => handleClick(type)}
            mods={isDarkTheme ? 'darkBG' : null}
          >
            {name}
          </Tag>
        );
      })}
    </div>
  );
}
