import { makeAutoObservable } from 'mobx';

export default class Header {
  searchVisibility = false;
  currentTab = null;
  currentLink = null;

  constructor() {
    makeAutoObservable(this);
  }

  toggleSearchVisible = open => {
    this.searchVisibility = open;
  };

  setCurrentTab = key => {
    this.currentTab = key;
  };

  setCurrentLink = key => {
    this.currentLink = key;
  };
}
