import { getBuildEnvironment } from 'utils/environment';

export const LOCAL_STORAGE_KEY = 'first-point';
export const SESSION_STORAGE_KEY = 'session-point';
export const DOMAIN_URL = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.com';
    case 'development':
      return 'https://dev.andersenlab.com';
    case 'production':
    default:
      return 'https://andersenlab.com';
  }
})();

export const DOMAIN_URL_UK = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.co.uk';
    case 'development':
      return 'https://dev.andersenlab.co.uk';
    case 'production':
    default:
      return 'https://andersenlab.co.uk';
  }
})();

export const DOMAIN_URL_DE = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.de';
    case 'development':
      return 'https://dev.andersenlab.de';
    case 'production':
    default:
      return 'https://andersenlab.de';
  }
})();

export const DOMAIN_URL_AE = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.ae';
    case 'development':
      return 'https://dev.andersenlab.ae';
    case 'production':
    default:
      return 'https://andersenlab.ae';
  }
})();

export const DOMAIN_URL_AZ = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.az';
    case 'development':
      return 'https://dev.andersenlab.az';
    case 'production':
    default:
      return 'https://andersenlab.az';
  }
})();

export const DOMAIN_URL_KZ = (() => {
  switch (getBuildEnvironment()) {
    case 'staging':
      return 'https://stage.andersenlab.kz';
    case 'development':
      return 'https://dev.andersenlab.kz';
    case 'production':
    default:
      return 'https://andersenlab.kz';
  }
})();

export const locales = [
  {
    hrefLang: 'x-default',
    domain: DOMAIN_URL,
  },
  {
    hrefLang: 'en',
    domain: DOMAIN_URL,
  },
  {
    hrefLang: 'en-GB',
    domain: DOMAIN_URL_UK,
  },
  {
    hrefLang: 'de',
    domain: DOMAIN_URL_DE,
  },
  {
    hrefLang: 'ar',
    domain: DOMAIN_URL_AE,
  },
  {
    hrefLang: 'az',
    domain: DOMAIN_URL_AZ,
  },
];

export const countries = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Democratic Republic of the Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Islamic Republic of Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Democratic People's Republic of Korea",
  'Republic of Korea',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Republic of North Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Federated States of Micronesia',
  'Republic of Moldova',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'RWANDA',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'United Republic of Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'British Virgin Islands',
  'U.S. Virgin Islands',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const EMPLOYEES_COUNT = '3500+';
export const PROJECTS_COUNT = '1000+';
export const OFFICES_COUNT = '16';
