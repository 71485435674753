import React, { useCallback, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useTooltipState, useWindowResize } from 'utils/hooks';
import Icon from '../Icon';
import { Tooltip } from '../index';
import * as styles from './Hint.module.scss';

function Hint({
  currentSlide,
  content,
  wrapperClassName,
  className,
  children,
  withIcon,
  iconColor,
  showOnlyOne = false,
  closeOnScroll = false,
  closeOnBackground = true,
  toggleOnHover = true,
  hintInsideModal = false,
  tailPlacement = 'auto',
  placement,
  theme,
}) {
  const { isOpen, showTooltip, hideTooltip } = useTooltipState();
  const tooltipRef = useRef(null);
  const { width } = useWindowResize();

  useEffect(() => {
    // this effect is needed to trigger tooltip closing on slide change for touch devices
    hideTooltip();
  }, [currentSlide]);

  const toggleOpen = useCallback(
    event => {
      event.preventDefault();

      if (closeOnBackground) {
        document.body.addEventListener('click', closeOnBody, { capture: true });
        tooltipRef.current?.addEventListener('click', closeOnBody, { capture: true });
      }
      isOpen ? onClose(event) : showTooltip();
    },
    [closeOnScroll, closeOnBackground, toggleOnHover, isOpen],
  );

  const onClose = useCallback(
    e => {
      if (tooltipRef.current?.contains(e.target) && e.target.id === 'tooltipCloseIcon') {
        e.stopPropagation();
      }
      if (closeOnBackground) {
        document.body.removeEventListener('click', closeOnBody, { capture: true });
        tooltipRef.current?.removeEventListener('click', closeOnBody, { capture: true });
      }
      hideTooltip();
    },
    [closeOnScroll, closeOnBackground, toggleOnHover, isOpen],
  );

  const closeOnBody = e => {
    if (tooltipRef.current?.contains(e.target) && e.target.id !== 'tooltipCloseIcon') {
      e.stopPropagation();
      return;
    }
    if ((!showOnlyOne && e.target.id === 'tooltipIcon') || e.target.id === 'tooltipCloseIcon') {
      return;
    }
    onClose(e);
  };

  const popup = (
    <div className={styles.content} ref={tooltipRef}>
      <div className={styles.text}>{content}</div>
      <Icon id="tooltipCloseIcon" name="close" className={styles.close} onClick={onClose} />
    </div>
  );

  return (
    <Tooltip
      isOpen={isOpen}
      closeHandler={onClose}
      closeOnScroll={closeOnScroll}
      node={popup}
      placement={placement}
      tailPlacement={tailPlacement}
      insideModal={hintInsideModal}
      wrapperClassName={wrapperClassName}
      className={className}
      theme={theme}
    >
      <div
        onMouseEnter={width >= 960 && toggleOnHover ? showTooltip : null}
        onMouseLeave={width >= 960 && toggleOnHover ? hideTooltip : null}
        onClickCapture={toggleOpen}
      >
        {children}
        {withIcon && (
          <div className={cn(styles.iconWrapper, styles[theme])}>
            <Icon
              id="tooltipIcon"
              name="info-icon"
              className={cn(styles.icon, isOpen && styles.active, styles[iconColor])}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
}

export default React.memo(Hint);
