import React from 'react';
import cn from 'classnames';
import * as styles from './TopScroller.module.scss';

export default function TopScroller({ className }) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return <button className={cn(styles.icon, className)} onClick={handleClick} aria-label='Go to the top' />;
}
