import { graphql, useStaticQuery } from 'gatsby';

export function useHeaderTranslation() {
  const { headerTranslation } = useStaticQuery(graphql`
    query {
      headerTranslation {
        data
      }
    }
  `);

  return JSON.parse(headerTranslation.data);
}
