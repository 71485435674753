import React, { Suspense, lazy } from 'react';
import { useInView } from 'react-intersection-observer';
import Loader from '../Loader';

const SlickSlider = lazy(() => import('react-slick').then(module => ({ default: module.default })));

const Slider = React.forwardRef(({ children, className, ...props }, ref) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={inViewRef} className={className}>
      {inView && (
        <Suspense fallback={<Loader theme="fullHeight" />}>
          <SlickSlider {...props} ref={ref}>
            {children}
          </SlickSlider>
        </Suspense>
      )}
    </div>
  );
});

Slider.displayName = 'Slider';

export default Slider;
