// extracted by mini-css-extract-plugin
export var container = "Container-module--container--687df";
export var fadeInAnimation = "Container-module--fadeInAnimation--00594";
export var fullWidth = "Container-module--fullWidth--13c55";
export var grayBG = "Container-module--grayBG--31aa0";
export var marginNone = "Container-module--marginNone--4b528";
export var marginTop = "Container-module--marginTop--96c8f";
export var paddingBottomNone = "Container-module--paddingBottomNone--53b9c";
export var paddingTopNone = "Container-module--paddingTopNone--cbecc";
export var paddingVertical = "Container-module--paddingVertical--24f08";
export var paddingWithout = "Container-module--paddingWithout--3d5aa";
export var transparentBG = "Container-module--transparentBG--79355";
export var whiteBG = "Container-module--whiteBG--9f44a";