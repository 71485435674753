import React, { useCallback, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { CloseButton, Loader } from 'components/UI';
import { ScrollContext } from 'utils/scroll';
import { lockUnlockPage } from 'utils';
import { observer, modal as modalStore } from 'store';
import * as styles from './Modal.module.scss';

function Modal({
  isOpen,
  loader,
  closeModalCallback,
  animation = 'show',
  component,
  children,
  closeButton = true,
  containerMod,
  width,
  contentMod,
  theme,
  closeButtonTheme,
}) {
  const [active, setActive] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setActive(true);
      lockUnlockPage.lock();
    }
    return () => lockUnlockPage.unlock();
  }, [isOpen]);

  const onClose = useCallback(() => {
    setActive(false);
  }, []);

  const onAnimationEnd = useCallback(() => {
    !active && closeModalCallback();
  }, [active]);

  if (!isOpen) return null;
  const Children = component
    ? component
    : typeof children === 'function'
    ? children
    : () => children;

  return ReactDOM.createPortal(
    <ScrollContext.Provider value={modalRef.current}>
      <section
        id="modal"
        ref={modalRef}
        className={cn(styles.popup, !active && styles.overlayHide, theme && styles[theme])}
        onClick={event => {
          if (!event.target.classList.contains(styles.popup)) return;
          onClose();
          modalStore.modalID && modalStore.close();
        }}
      >
        {closeButton && <CloseButton onClose={onClose} test={closeButtonTheme} />}
        {loader && <Loader />}
        <div
          className={cn(
            styles.container,
            active && styles.active,
            animation && styles[animation],
            loader && styles.loading,
            containerMod && styles[containerMod],
            width && styles[width],
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={cn(styles.content, contentMod && styles[contentMod])}>
            {Children(onClose)}
          </div>
        </div>
      </section>
    </ScrollContext.Provider>,
    document.getElementById('___gatsby'),
  );
}

export default observer(Modal);
