import React, { lazy, Suspense } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookiesPolicy from 'components/CookiesPolicy';

const SuccessModal = lazy(() =>
  import('components/Modals').then(({ SuccessModal }) => ({
    default: SuccessModal,
  })),
);
const ErrorModal = lazy(() =>
  import('components/Modals').then(({ ErrorModal }) => ({
    default: ErrorModal,
  })),
);
const ConsentModal = lazy(() =>
  import('components/Modals').then(({ ConsentModal }) => ({
    default: ConsentModal,
  })),
);
const LangNotificationModal = lazy(() =>
  import('components/Modals').then(({ LangNotificationModal }) => ({
    default: LangNotificationModal,
  })),
);

function PageLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Suspense fallback={null}>
        <SuccessModal />
        <ErrorModal />
        <ConsentModal />
        {(process.env.GATSBY_LANG === 'ae' || process.env.GATSBY_LANG === 'az') && (
          <LangNotificationModal />
        )}
      </Suspense>
      <CookiesPolicy />
    </>
  );
}

export default PageLayout;
