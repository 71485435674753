import React from 'react';
import { getIsSsr } from 'utils/environment';
import Item from './Item';
import { defaultXl, defaultLg, defaultMd, defaultSm, defaultXsm } from './constants';

import * as styles from './Grid.module.scss';

export default function Grid({
  xsm = {},
  sm = {},
  md = {},
  lg = {},
  xl = {},
  gutterX,
  gutterY,
  children,
}) {
  const style = {
    '--columnsXl': xl.cols ?? defaultXl.cols,
    '--columnsLg': lg.cols ?? defaultXl.cols,
    '--columnsMd': md.cols ?? defaultMd.cols,
    '--columnsSm': sm.cols ?? defaultSm.cols,
    '--columnsXsm': xsm.cols ?? defaultXsm.cols,
    '--gutterX-xl': xl.gutterX ?? gutterX ?? defaultXl.gutterX,
    '--gutterX-lg': lg.gutterX ?? gutterX ?? defaultLg.gutterX,
    '--gutterX-md': md.gutterX ?? gutterX ?? defaultMd.gutterX,
    '--gutterX-sm': sm.gutterX ?? gutterX ?? defaultSm.gutterX,
    '--gutterX-xsm': xsm.gutterX ?? defaultXsm.gutterX ?? gutterX,
    '--gutterY-xl': xl.gutterY ?? gutterY ?? defaultXl.gutterY,
    '--gutterY-lg': lg.gutterY ?? gutterY ?? defaultLg.gutterY,
    '--gutterY-md': md.gutterY ?? gutterY ?? defaultMd.gutterY,
    '--gutterY-sm': sm.gutterY ?? gutterY ?? defaultSm.gutterY,
    '--gutterY-xsm': xsm.gutterY ?? gutterY ?? defaultXsm.gutterY,
  };

  if (!getIsSsr()) {
    React.Children.forEach(children, child => {
      if (child && child.type !== Item) {
        if (window) {
          window.console.log(
            '%c%s',
            'color: red; font: 1.2rem/1 sans-serif;',
            'Warning! Use GridItem to render Grid children.',
          );
        }
      }
    });
  }
  return (
    <div className={styles.grid} style={style}>
      {children}
    </div>
  );
}
