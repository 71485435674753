import React from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import * as styles from './Button.module.scss';

function Button({
  theme = 'primary',
  size = 'md',
  fullWidthMobile,
  disabled,
  children,
  className,
  tag = 'button',
  ...other
}) {
  const Wrapper = tag === 'a' ? Link : tag;
  return (
    <Wrapper
      className={cn(
        styles.button,
        theme && styles[theme],
        size && styles[size],
        fullWidthMobile && styles.fullWidthMobile,
        disabled && styles.disabled,
        className,
      )}
      disabled={disabled}
      {...other}
    >
      {children}
    </Wrapper>
  );
}

export default Button;
