import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import * as styles from './AnchorMenu.module.scss';

export default function AnchorMenu({ anchorLinks, headerFixed }) {
  const [elementInView, setElementInView] = useState(null);

  useEffect(() => {
    const handlerScroll = () => {
      const scrollPosition = window.scrollY - 200;

      const selected = anchorLinks?.find(({ anchor }) => {
        const ele = document.getElementById(anchor);

        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        } else {
          return false;
        }
      });

      if (selected && selected.anchor !== elementInView) {
        setElementInView(selected.anchor);
      } else {
        setElementInView(null);
      }
    };

    window.addEventListener('scroll', handlerScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [anchorLinks, headerFixed]);

  const handleClick = anchor => {
    const scrollTarget = document.getElementById(anchor);
    scrollTarget?.scrollIntoView({ behavior: 'smooth' });
  };

  return anchorLinks ? (
    <div className={cn(styles.wrapper, headerFixed && styles.visible)}>
      <div className={styles.layout}>
        {anchorLinks?.map(({ anchor, title }) => {
          return (
            <button
              key={anchor}
              className={cn(styles.link, anchor === elementInView && styles.current)}
              onClick={() => handleClick(anchor)}
            >
              {title}
            </button>
          );
        })}
      </div>
    </div>
  ) : null;
}

function getDimensions(ele) {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}
