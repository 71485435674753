// extracted by mini-css-extract-plugin
export var active = "Tag-module--active--20d48";
export var darkBG = "Tag-module--darkBG--75f8f";
export var fadeInAnimation = "Tag-module--fadeInAnimation--f2445";
export var tag = "Tag-module--tag--4cb03";
export var themeCalculatorTag = "Tag-module--themeCalculatorTag--9b20c";
export var themeFiltered = "Tag-module--themeFiltered--a81c0";
export var themeFilteredGray = "Tag-module--themeFilteredGray--89574";
export var themeFilteredTail = "Tag-module--themeFilteredTail--252fe";
export var themeFormTag = "Tag-module--themeFormTag--ecd50";
export var themeOval = "Tag-module--themeOval--f594e";
export var themeRectangular = "Tag-module--themeRectangular--19267";
export var withoutMT = "Tag-module--withoutMT--1772c";