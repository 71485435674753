export const loadedScriptsOnScroll = 'loadedScriptsOnScroll';

export const recaptchSrc =
  'https://www.google.com/recaptcha/api.js?render=6LfCPMAUAAAAAGPj2JvQRyB45mU8iJabAxT_jLu6';

export const intercom = {
  settings: `window.intercomSettings = { api_base: "https://api-iam.intercom.io", app_id: "syf6cyhy" };`,
  script: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function")
  {ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function()
  {i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function()
  {var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/syf6cyhy';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
};

export const localBusinessSchema = {
  en: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Andersen Inc.",
    "image": "https://static.andersenlab.com/andersenlab/new-andersensite/logos/andersen-preview-image.png",
    "telephone": "+48223906408" ,
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "Rondo Daszyńskiego 1 23 floor",
      "addressLocality": "Warsaw",
      "postalCode": "00-843",
      "addressCountry": "PL"
    }
  }`,
  de: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Andersen Inc.",
    "image": "https://static.andersenlab.com/andersenlab/new-andersensite/logos/andersen-preview-image.png",
    "telephone": "+4922198253169" ,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Zollstockgürtel 67",
      "addressLocality": "Cologne",
      "postalCode": "50969",
      "addressCountry": "DE"
    }
  }`,
  ae: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Andersen Inc.",
    "image": "https://static.andersenlab.com/andersenlab/new-andersensite/logos/andersen-preview-image.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "The One Tower, Sheik Zayed Road, 23 floor",
      "addressLocality": "Dubai",
      "addressCountry": "UAE"
    }
  }`,
  az: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Andersen Inc.",
    "image": "https://static.andersenlab.com/andersenlab/new-andersensite/logos/andersen-preview-image.png",
    "telephone": "+994507852453" ,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Baku White City Office Bulding, 25, 8 Noyabr Prospekti",
      "addressLocality": "Baku",
      "postalCode": "AZ1025",
      "addressCountry": "AZ"
    }
  }`,
  uk: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Andersen Inc.",
    "image": "https://static.andersenlab.com/andersenlab/new-andersensite/logos/andersen-preview-image.png",
    "telephone": "+442070486755" ,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "30 St Mary's Axe",
      "addressLocality": "London",
      "postalCode": "EC3A 8BF",
      "addressCountry": "UK"
    }
  }`,
};
