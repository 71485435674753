// extracted by mini-css-extract-plugin
export var button = "Tabs-module--button--be274";
export var buttonRow = "Tabs-module--buttonRow--f1fd4";
export var buttons = "Tabs-module--buttons--fd7ed";
export var currentTab = "Tabs-module--currentTab--99159";
export var fadeInAnimation = "Tabs-module--fadeInAnimation--72841";
export var line = "Tabs-module--line--f0e78";
export var logo = "Tabs-module--logo--7effe";
export var scrollButtonLeft = "Tabs-module--scrollButtonLeft--740c6";
export var scrollButtonRight = "Tabs-module--scrollButtonRight--57737";
export var white = "Tabs-module--white--f4504";
export var withLogo = "Tabs-module--withLogo--dac28";
export var wrapper = "Tabs-module--wrapper--b64f6";