exports.getCookie = function (name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : null;
};

exports.setCookie = function (name, value, options = {}) {
  const _options = { path: '/', ...options };

  if (options.expires instanceof Date) {
    _options.expires = options.expires.toUTCString();
  }

  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.keys(_options).forEach(key => {
    cookie += `; ${key}`;
    const option = _options[key];
    if (option !== true) {
      cookie += `=${option}`;
    }
  });

  document.cookie = cookie;
};
