import { appendScriptSrc, appendScriptInnerHtml } from 'utils/scripts';
import { loadedScriptsOnScroll, recaptchSrc, intercom } from 'define/scripts';
import { isRequestedFrom } from 'utils/localisation';
import { storage } from 'store';
require('assets/scss/app.scss');
require('lazysizes');
require('lazysizes/plugins/attrchange/ls.attrchange');
require('./static/entry-points');
require('./static/gclid');

const appendScriptsOnScrollOptions = { once: true };

const appendScriptsOnScroll = () => {
  if (!isRequestedFrom(['ua', 'by', 'ru'])) {
    appendScriptInnerHtml(intercom.settings, 'body');
    appendScriptInnerHtml(intercom.script, 'body');
  }

  const captchaLoaded = document.getElementById('recaptcha');

  if (!captchaLoaded) {
    appendScriptSrc(recaptchSrc, 'body', 'recaptcha');
  }

  sessionStorage.setItem(loadedScriptsOnScroll, 'true');
};

const initStorage = () => {
  const videoLoaded = storage.getItem('showVideo');

  if (!videoLoaded) {
    storage.addItem('showVideo', true);
  }

  window.removeEventListener('load', initStorage);
};

export const onClientEntry = () => {
  window.addEventListener('load', initStorage);
};

export const onInitialClientRender = () => {
  window.addEventListener('scroll', appendScriptsOnScroll, appendScriptsOnScrollOptions);
};

export const onRouteUpdate = ({ prevLocation }) => {
  const checkLoadedScripts = sessionStorage.getItem(loadedScriptsOnScroll);

  if (prevLocation && !checkLoadedScripts) {
    window.removeEventListener('scroll', appendScriptsOnScroll, appendScriptsOnScrollOptions);
    appendScriptsOnScroll();
  }
};

export const wrapPageElement = require(`./src/components/PageLayout`).default;
export const wrapRootElement = require(`./src/components/RootWrapper`).default;

export const disableCorePrefetching = () => true;
