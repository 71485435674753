import { makeAutoObservable } from 'mobx';

export default class InsightsShorts {
  currentIndex = 0;
  progress = 0;
  currentTime = 0;
  muted = false;
  volumeOfVideo = 100;
  playing = false;

  constructor() {
    makeAutoObservable(this);
  }

  resetShortsStore = () => {
    this.progress = 0;
    this.currentTime = 0;
    this.muted = false;
    this.volumeOfVideo = 100;
    this.playing = false;
  };

  setCurrentIndex = payload => {
    this.currentIndex = payload;
  };

  setCurrentTime = payload => {
    this.currentTime = payload;
  };

  setProgress = payload => {
    this.progress = payload;
  };

  setMuted = payload => {
    this.muted = payload;
  };

  setVolumeOfVideo = payload => {
    this.volumeOfVideo = payload;
  };

  setPlaying = payload => {
    this.playing = payload;
  };
}
