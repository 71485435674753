import { makeAutoObservable } from 'mobx';

export default class StepsCalculator {
  currentStep = 1;
  payload = {};
  isSubmitting = false;
  previouslyOpened = null;
  price = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPrice = value => {
    this.price = value;
  };

  setIsSubmitting = value => {
    this.isSubmitting = value;
  };

  setPreviouslyOpened = value => {
    this.previouslyOpened = value;
  };

  setStep = step => {
    this.currentStep = step;
  };

  nextStep = () => {
    this.currentStep += 1;
  };

  prevStep = () => {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
    }
  };

  setPayload = value => {
    this.payload = value;
  };

  getPayload = () => {
    return this.payload;
  };

  setFieldValue = (fieldName, value) => {
    if (!value || (Array.isArray(value) && !value?.length) || !Object.keys(value)?.length) {
      this.removeFieldValue(fieldName);
    } else {
      this.payload[fieldName] = value;
    }
  };

  getFieldValue = fieldName => {
    return this.payload[fieldName] ?? null;
  };

  removeFieldValue = fieldName => {
    delete this.payload[fieldName];
  };
}
