import React from 'react';
import { Link, Icon } from 'components/UI';
import * as styles from './SocialMediaIcon.module.scss';

export default function SocialMediaIcon() {
  return (
    <>
      <Link href="https://www.facebook.com/AndersenSoftwareDev" className={styles.link}>
        <Icon name="facebook-circle" className={styles.icon} aria-label="Facebook link" />
      </Link>
      <Link href="https://www.xing.com/pages/andersen" className={styles.link}>
        <Icon name="xing-circle" className={styles.icon} aria-label="Xing link" />
      </Link>
      <Link href="https://www.linkedin.com/company/andersen-lab" className={styles.link}>
        <Icon name="linkedin-circle" className={styles.icon} aria-label="Linkedin link" />
      </Link>
      <Link href="https://twitter.com/AndersenLabs" className={styles.link}>
        <Icon name="twitter-round" className={styles.icon} aria-label="Twitter link" />
      </Link>
      <Link href="https://www.instagram.com/andersen.global/" className={styles.link}>
        <Icon name="instagram-circle" className={styles.icon} aria-label="Instagram link" />
      </Link>
      <Link href="https://www.behance.net/andersenlab_design" className={styles.link}>
        <Icon name="behance-circle" className={styles.icon} aria-label="Behance link" />
      </Link>
      <Link href="https://dribbble.com/andersen-design" className={styles.link}>
        <Icon name="dribbble" className={styles.icon} aria-label="Dribbble link" />
      </Link>
      <Link
        href="https://clutch.co/profile/andersen?utm_source=widget&utm_medium=2&utm_campaign=widget&utm_content=stars#reviews"
        className={styles.link}
      >
        <Icon name="clutch" className={styles.clutchIcon} aria-label="Clutch link" />
      </Link>
    </>
  );
}
