import React from 'react';
import cn from 'classnames';
import { Link, Title } from 'components/UI';
import * as styles from './TitleLink.module.scss';

export default function TitleLink({
  className,
  href,
  target,
  tag = 'span',
  size,
  color = 'black',
  children,
}) {
  return (
    <Link href={href} className={cn(styles.link, className, styles[color])} target={target}>
      <Title tag={tag} className={styles.title} size={size} color={color}>
        {children}
      </Title>
    </Link>
  );
}
