import React from 'react';
import cn from 'classnames';
import * as styles from './BurgerBtn.module.scss';

export default function BurgerBtn({ active = false, theme = 'default', onClick, fixed = false }) {
  return (
    <div
      className={cn(styles.button, styles[theme], active && styles.active, fixed && styles.fixed)}
      aria-hidden="true"
      onClick={onClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
      aria-label="Open the menu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
