import React from 'react';
import { Link } from 'components/UI';
import * as styles from './NavColumn.module.scss';

export default function NavColumn({ title, items }) {
  return (
    <div className={styles.column}>
      {title && <p className={styles.title}>{title}</p>}
      {items?.map(({ title: label, link, target }) => {
        return (
          <Link key={label} href={link} target={target} className={styles.link}>
            {label}
          </Link>
        );
      })}
    </div>
  );
}
