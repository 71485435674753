import React from 'react';

import cn from 'classnames';
import { Grid, GridItem, SelectMultiple, Tag, Checkbox, Hint, Title } from 'components/UI';
import { useTrans } from 'utils/localisation';
import { upperFirst } from 'utils';
import { useHorizontalScroll } from 'utils/hooks';

import * as styles from './Filters.module.scss';

export default function Filters({
  title,
  filters,
  defaultFilters,
  setFilters,
  options,
  translation = 'common',
  theme = '',
  classname,
  gridSettings,
  selectedFiltersLabel,
}) {
  const t = useTrans(['filters', translation]);
  const isSelectedFilters = hasSelectedFilters(filters);
  const { scrollRef } = useHorizontalScroll(isSelectedFilters);

  const setItem = (item, option, type) =>
    setFilters(prev => ({
      ...prev,
      [option]: type === 'radio' ? item.slice(-1) : item,
    }));

  const removeItem = (item, option) => {
    setItem(without(filters[option])(item), option);
  };

  const handleCheckboxClick = item => {
    const result = filters.checkboxes.some(filter => filter === item)
      ? filters.checkboxes.filter(filter => filter !== item)
      : [...filters.checkboxes, item];

    setItem(result, 'checkboxes');
  };

  const clear = () => setFilters(defaultFilters);

  const defaultGridSettings = {
    xsm: { cols: 1, gutterY: '24px' },
    sm: { cols: 3 },
    md: { cols: 4 },
    lg: { cols: 4 },
    xl: { cols: 4 },
    gutterY: '40px',
  };

  return (
    <div className={cn(styles.content, classname)}>
      {title && <Title className={styles.title}>{title}</Title>}
      <Grid {...(gridSettings ?? defaultGridSettings)}>
        {Object.keys(options).map(option => {
          const { onlyPlaceholder, placeholder, type, values, withSearch, notInFilters } =
            options[option];
          if (notInFilters) return null;

          return (
            <GridItem Tag="div" key={option}>
              <SelectMultiple
                options={values}
                onlyPlaceholder={t(onlyPlaceholder)}
                placeholder={t(placeholder)}
                onSelect={item => setItem(item, option, type)}
                selected={filters[option]}
                withSearch={withSearch}
                theme={theme}
                type={type}
              />
            </GridItem>
          );
        })}
      </Grid>
      {options.checkboxes?.values?.length > 0 && (
        <div className={styles.filtersRow}>
          <span className={styles.rowTitle}>{options.checkboxes?.text}</span>
          {options.checkboxes.values.sort(options.checkboxes.checkboxSort).map(item => (
            <Checkbox
              key={item}
              onChange={() => handleCheckboxClick(item)}
              checked={filters.checkboxes.some(filter => filter === item)}
              className={styles.checkbox}
            >
              <span className={styles.checkboxTitle}>{item}</span>
              {t(item) && (
                <Hint
                  wrapperClassName={styles.tooltipWrapper}
                  className={styles.tooltip}
                  toggleOnHover
                  withIcon
                  content={t(item)}
                />
              )}
            </Checkbox>
          ))}
        </div>
      )}
      {isSelectedFilters && (
        <div className={styles.tags}>
          {selectedFiltersLabel && (
            <span className={cn(styles.text, styles[`theme${upperFirst(theme)}`])}>
              {t('selectedFilters')}:
            </span>
          )}
          <div className={styles.tagsWrapper} ref={scrollRef}>
            {Object.keys(filters).map(type =>
              filters[type].map(filter => (
                <Tag
                  key={filter.value ?? filter}
                  theme="filteredGray"
                  mods={[theme]}
                  onClick={() => removeItem(filter, type)}
                >
                  {filter.label ?? filter}
                </Tag>
              )),
            )}
          </div>
          <button onClick={clear} className={cn(styles.clear, styles[`theme${upperFirst(theme)}`])}>
            {t('clear')}
          </button>
        </div>
      )}
    </div>
  );
}

function without(arr) {
  return element => arr.filter(item => item !== element);
}

function hasSelectedFilters(filters) {
  return Boolean(
    Object.values(filters).some(category => Array.isArray(category) && category.length),
  );
}
