import { makeAutoObservable } from 'mobx';

export default class Cookies {
  seen = true;
  theme = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSeen = status => {
    this.seen = status;
  };

  setTheme = type => {
    this.theme = type;
  };
}
