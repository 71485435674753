import { useCallback, useEffect, useMemo, useState } from 'react';
import { pushCustomEvent } from 'utils/analytics';

export const useCardsLayout = (pageSize, length, page) => {
  // Override default pageSize of 3 if page has only 4 cases to display grid with 4 small cards
  const preparedSize = page !== '/case-studies' && length === 4 ? 4 : pageSize;

  const [count, setCount] = useState(preparedSize);
  const [width, setWidth] = useState(null);

  const deviceType = useMemo(() => {
    if (width < 544) {
      return 'mobile';
    }
    return 'desktop';
  }, [width]);

  const showCount =
    page !== '/case-studies' && deviceType === 'desktop' && length - count === 4 ? 4 : 3;

  useEffect(() => {
    if (page !== '/case-studies') {
      setCount(preparedSize);
    }
  }, [page, length]);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const showMore = useCallback(() => {
    setCount(prev => prev + showCount);
    pushCustomEvent('ndrsn_projects_block', {
      action: 'click_on_button',
      label: 'see_more',
    });
  }, [showCount]);

  function checkCardWidth(i, length) {
    let lgWidth;

    switch (deviceType) {
      case 'desktop':
        if (page === '/services/artificial-intelligence/ai-tools-for-business-transformation') {
          lgWidth = checkAiToolsLgWidth(i, length);
        } else {
          lgWidth = checkProjectsLgWidth(i, length);
        }
        break;
      default:
        lgWidth = false;
        break;
    }

    if (lgWidth) return 'lgWidth';

    return 'smWidth';
  }

  function checkHide(i) {
    return i > count;
  }

  return { count, showMore, checkCardWidth, checkHide };
};

function checkProjectsLgWidth(i, length) {
  // if there are only four cards then all of them must be small
  if (length === 4) return false;

  // if there are only one card then it always must be large
  if (i === 0 && length === 1) return true;

  // if the last card is small then previous to last also should be small if it is large
  if (length > 4 && i === length - 2 && (i + 1) % 3 === 0) return false;

  // default rule that every third card should be large
  if ((i + 1) % 3 === 0) return true;

  return false;
}

function checkAiToolsLgWidth(i, length) {
  // the first card always should be large
  if (i === 0) return true;

  // if one card left in the end it should be large
  if (i === length - 1 && (i + 1) % 2 === 0) return true;

  return false;
}
