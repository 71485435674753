export const appendScriptSrc = (src, location, id) => {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;
  scriptElement.async = true;
  scriptElement.id = id;
  document[location].appendChild(scriptElement);
};

export const appendScriptInnerHtml = (scriptInnerHTML, location) => {
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = scriptInnerHTML;
  document[location].appendChild(scriptElement);
};
