import React from 'react';
import cn from 'classnames';

import SubmenuLink from './SubmenuLink';
import Markup from 'components/Markup';
import { Link, Button } from 'components/UI';

import * as styles from './SubMenu.module.scss';

function SubMenu({
  leftContent = {},
  rightContent = [],
  parentLink,
  active,
  orientation = 'left',
  topFixed,
  itemKey,
  currentLink,
  theme,
  className,
}) {
  const orient = orientation === 'left' ? styles.left : styles.right;

  const renderLinks = links => {
    if (links.find(Array.isArray)) {
      const firstLink = links[0];
      return (
        <>
          <SubmenuLink
            link={firstLink}
            itemKey={itemKey}
            currentLink={currentLink}
            mod="firstLink"
          />
          <div className={styles.sublinks}>
            {links[1].map((link, key) => (
              <SubmenuLink link={link} key={key} itemKey={itemKey} currentLink={currentLink} />
            ))}
          </div>
        </>
      );
    }

    return links.map((link, key) => (
      <SubmenuLink link={link} key={key} itemKey={itemKey} currentLink={currentLink} />
    ));
  };

  const rightElement = rightContent.map((el, i) => {
    if (Array.isArray(el)) {
      return (
        <div className={styles.item} key={i}>
          <div className={styles.list}>{renderLinks(el)}</div>
        </div>
      );
    }

    return null;
  });

  const renderLeftContent = () => {
    return (
      leftContent.title && (
        <div className={styles.column}>
          <p className={styles.title}>{leftContent.title}</p>
          <Markup key={leftContent.description} tag="p" className={styles.description}>
            {leftContent.description}
          </Markup>
          {leftContent?.button && (
            <Button
              className={styles.button}
              tag="a"
              href={leftContent.button.href}
              target={leftContent.button?.target}
              theme="secondary"
            >
              {leftContent.button.name}
            </Button>
          )}
        </div>
      )
    );
  };

  return (
    <div
      className={cn(
        styles.subMenu,
        active && styles.visible,
        orient,
        !topFixed || styles.topfixed,
        styles[theme],
        className,
      )}
    >
      {renderLeftContent()}
      <div className={cn(styles.column, !parentLink && styles.rightElementsWrap)}>
        {parentLink ? (
          <>
            <div className={styles.rightElementsWrap}>{rightElement}</div>
            <Link href={parentLink.href} className={styles.parentLink}>
              {parentLink.name}
            </Link>
          </>
        ) : (
          rightElement
        )}
      </div>
    </div>
  );
}

export default React.memo(SubMenu);
