// extracted by mini-css-extract-plugin
export var fadeInAnimation = "Tooltip-module--fadeInAnimation--d2a0c";
export var fullWidth = "Tooltip-module--fullWidth--51104";
export var insideModal = "Tooltip-module--insideModal--e32e1";
export var manualTailPosition = "Tooltip-module--manualTailPosition--53dbe";
export var nda = "Tooltip-module--nda--b6f5c";
export var tail = "Tooltip-module--tail--580d5";
export var tail_center = "Tooltip-module--tail_center--20d4a";
export var tail_end = "Tooltip-module--tail_end--c72e3";
export var tail_none = "Tooltip-module--tail_none--660ea";
export var tail_start = "Tooltip-module--tail_start--8eede";
export var text = "Tooltip-module--text--bd248";
export var tooltip = "Tooltip-module--tooltip--269be";
export var wrapper = "Tooltip-module--wrapper--69ae3";