import React from 'react';
import cn from 'classnames';

import * as styles from './CheckMark.module.scss';

function CheckMark({ size = 'md', checked, className }) {
  return (
    <span className={cn(styles.checkMark, styles[size], checked && styles.checked, className)} />
  );
}

export default CheckMark;
