import React, { useCallback, useState } from 'react';
import { CollapseEl } from 'components/UI';
import cn from 'classnames';
import * as styles from './Accordion.module.scss';

export default function Accordion({ title, children, isOpened = false, className }) {
  const [state, setState] = useState({ collapse: isOpened });
  const toggleListHandler = useCallback(
    () => setState(prev => ({ ...prev, collapse: !prev.collapse })),
    [],
  );

  return (
    <>
      <button
        className={cn(styles.panel, className, state.collapse && styles.active)}
        onClick={toggleListHandler}
      >
        {title && <span className={styles.title}>{title}</span>}
      </button>
      <CollapseEl toggle={state.collapse} className={styles.panelContentWrapper}>
        {children}
      </CollapseEl>
    </>
  );
}
