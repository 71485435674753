import React from 'react';
import cn from 'classnames';
import MobileNav from './MobileNav';
import { Button } from 'components/UI';
import { useTrans, useLang } from 'utils/localisation';

import * as styles from './MobileMenu.module.scss';

export default function MobileMenu({ links }) {
  const lang = useLang();
  const t = useTrans(['header']);
  const contactUsLink =
    lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/contact-us' : '/contact-us';
  const newLinks = links.sort((a, b) => {
    if (a.key === 'Company') return 1;
    if (b.key === 'Company') return -1;
    return 0;
  });

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.container}>
        <MobileNav links={newLinks} />
        <div className={styles.buttonLine}>
          <Button
            tag="a"
            className={cn(styles.button, lang === 'de' && styles.de)}
            href={contactUsLink}
          >
            {t('contactUs')}
          </Button>
        </div>
      </div>
    </div>
  );
}
