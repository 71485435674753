import { makeAutoObservable } from 'mobx';
export default class Calculator {
  calculators = {};

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description получение итогового кол-ва выбранных специалистов в калькуляторе
   * @param {String} id id калькулятора
   * @return {Number} кол-во выбранных специалистов
   */
  getTotalTeam = id => {
    const { total, info } = this.calculators[id];

    const team = total.team?.reduce((acc1, name) => {
      const sectionTotal = Object.values(info[name]).reduce(
        (acc2, value) => acc2 + Number(value),
        0,
      );

      return acc1 + sectionTotal;
    }, 0);

    const duration = total.duration?.reduce((acc, name) => {
      const sectionTotal = Object.entries(info[name]).find(([, value]) => value);

      /**
       * Забираем число из значения ключа для передачи в TotalBox
       */
      return acc + Array.isArray(sectionTotal) ? Number(sectionTotal[0].match(/\d+/)[0]) : 0;
    }, 0);

    return { team, duration };
  };

  /**
   * @description получение итогового кол-ва выбранных элементов в секции
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @return {Number} кол-во выбранных элементов
   */
  getSectionTotal = (id, key) => {
    const sectionInfo = this.getSectionInfo(id, key);

    return Object.values(sectionInfo).reduce((acc, value) => acc + Number(value), 0);
  };

  /**
   * @description получение сохраненных данных о выбранных элементах в секции
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @return {Objeсt} Объект с сохраненными данными
   * @example {Designer: 2, ...}
   */
  getSectionInfo = (id, key) => this.calculators[id].info[key];

  /**
   * @description получение сохраненных данных элемента секции
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @param {String} name имя элемента
   * @return  {(Number|String|Boolean) значение элемента
   */
  getCounterInfo = (id, key, name) => this.calculators[id].info[key][name];

  /**
   * @description получение domain
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @return {(Array|undefined)) значение элемента
   * @example [Healthcare, true]
   */
  getCurrentDomain = (id, key) => {
    const data = this.calculators[id].info[key];

    return data
      ? Object.entries(data).find(([fieldName, value]) => Boolean(value)) ?? ['Default']
      : ['Default'];
  };

  /**
   * @description получение состояния калькулятора, нужно для калькуляторов
   * с блокировкой кнопки вызова результирующей модалки
   * @param {String} id id калькулятора
   * @return {Boolean}
   */
  getCalculatorActivity = (id, exclude = []) => {
    return Object.entries(this.calculators[id].info).some(([key, values]) => {
      const isExlude = exclude.some(_key => _key === key);

      if (isExlude) return false;

      return Object.values(values).some(Boolean);
    });
  };

  /**
   * @description инициализация состояния калькулятора
   * @param {String} id id калькулятора
   * @param {Object} initialState начальное состояние
   */
  setCalculator = (id, initialState) => (this.calculators[id] = initialState);

  /**
   * @description удаление состояния калькулятора
   * @param {String} id id калькулятора
   */
  destroyCalculator = id => delete this.calculators[id];

  /**
   * @description изменить значение элемента
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @param {String} name имя элемента
   * @param {(Number|String|Boolean)} value новое значение
   */
  updateElementInfo = (id, key, name, value) => (this.calculators[id].info[key][name] = value);

  /**
   * @description изменить значение элемента в списке выбранных
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @param {String} name имя элемента
   * @param {(Number|String|Boolean)} value новое значение
   */
  updateCurrentList = (id, key, name, value) => {
    const elementName = `${key}-${name}`;
    if (value) {
      this.calculators[id].currentList.set(elementName, {
        sectionName: key,
        name,
        value,
      });
    } else {
      this.calculators[id].currentList.delete(elementName);
    }
  };

  /**
   * @description изменить значение секции
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   * @param {Object} value новое значение
   */
  updateSectionInfo = (id, key, values) => (this.calculators[id].info[key] = values);

  /**
   * @description очистить данные калькулятора до нулевых значений
   * @param {String} id id калькулятора
   */
  clearCalculatorInfo = id => {
    const info = this.calculators[id].info;

    Object.keys(info).forEach(key => {
      const sectionInfo = info[key];

      Object.keys(sectionInfo).forEach(name => {
        const type = typeof sectionInfo[name];
        const value = type === 'number' ? 0 : false;

        sectionInfo[name] = value;
        this.updateCurrentList(id, key, name, value);
      });
    });
  };

  /**
   * @description очистить данные секции до нулевых значений
   * @param {String} id id калькулятора
   * @param {String} key имя секции
   */
  clearSectionInfo = (id, key, value) => {
    const sectionInfo = this.calculators[id].info[key];

    Object.keys(sectionInfo).forEach(name => {
      sectionInfo[name] = value;
      this.updateCurrentList(id, key, name, value);
    });
  };

  /**
   * @description очистить данные секции до нулевых значений
   * @param {String} id id калькулятора
   * @param {Boolean} toggle активна/неактивна
   */
  toggleModal = (id, toggle) => {
    const modal = this.calculators[id].modal;

    modal.show = toggle;
  };

  /**
   * @description открыть превью модалку
   * @param {String} id id калькулятора
   * @param {Boolean} toggle активна/неактивна
   * @param {Boolean} closed модалка была закрыта пользователем
   */
  togglePreview = (id, toggle, closed) => {
    const modal = this.calculators[id].modal.preview;

    if (modal.isClosed) return;

    if (closed) {
      modal.isClosed = closed;
    }

    modal.show = toggle;
  };

  /**
   * @description set calculation result for pricing page to display in Result modal
   * @param {String} id calculator Id
   * @param {String} price price range
   * @example $1231 - $12312
   */
  setPrice = (id, price) => {
    this.calculators[id].priceRange = price;
  };

  /**
   * @description get calculation result for pricing page to display in Result modal
   * @param {String} id calculator Id
   */
  getPrice = id => {
    if (this.calculators[id].priceRange) {
      return this.calculators[id].priceRange;
    }
  };
}
