import React from 'react';
import cn from 'classnames';
import * as styles from './Title.module.scss';

export default function Title({
  className,
  tag = 'h2',
  size = tag,
  color = 'black',
  children,
  ...rest
}) {
  const Wrapper = tag;

  return (
    <Wrapper className={cn(styles.title, className, size && styles[size], styles[color])} {...rest}>
      {children}
    </Wrapper>
  );
}
