// extracted by mini-css-extract-plugin
export var arrow = "LangSwitcher-module--arrow--9b3a5";
export var arrowIcon = "LangSwitcher-module--arrowIcon--d2eb3";
export var arrowRotated = "LangSwitcher-module--arrowRotated--6770c";
export var arrowRotatedWhite = "LangSwitcher-module--arrowRotatedWhite--ab0a0";
export var fadeInAnimation = "LangSwitcher-module--fadeInAnimation--26341";
export var item = "LangSwitcher-module--item--e78dd";
export var langSwitcher = "LangSwitcher-module--langSwitcher--172b9";
export var link = "LangSwitcher-module--link--d1e93";
export var linkWithoutArrow = "LangSwitcher-module--linkWithoutArrow--881c6";
export var list = "LangSwitcher-module--list--9a5f6";
export var showSubmenu = "LangSwitcher-module--showSubmenu--bb064";
export var smallArrowIcon = "LangSwitcher-module--smallArrowIcon--0167c";
export var submenu = "LangSwitcher-module--submenu--473b0";