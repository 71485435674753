// extracted by mini-css-extract-plugin
export var active = "MainNav-module--active--12987";
export var arrow = "MainNav-module--arrow--fe86c";
export var arrowIcon = "MainNav-module--arrowIcon--49e7f";
export var arrowRotated = "MainNav-module--arrowRotated--dd59b";
export var arrowRotatedActive = "MainNav-module--arrowRotatedActive--77eb5";
export var arrowRotatedWhite = "MainNav-module--arrowRotatedWhite--d0da3";
export var current = "MainNav-module--current--9077a";
export var dark = "MainNav-module--dark--1f90b";
export var fadeInAnimation = "MainNav-module--fadeInAnimation--85879";
export var fixed = "MainNav-module--fixed--963eb";
export var icon = "MainNav-module--icon--6987e";
export var indexPage = "MainNav-module--indexPage--4569b";
export var item = "MainNav-module--item--2c157";
export var lastItemNoPadding = "MainNav-module--lastItemNoPadding--54068";
export var light = "MainNav-module--light--90e8b";
export var link = "MainNav-module--link--1e744";
export var list = "MainNav-module--list--5f959";
export var mainNav = "MainNav-module--mainNav--40cc7";
export var showSubMenu = "MainNav-module--showSubMenu--38c0e";
export var submenu = "MainNav-module--submenu--74154";
export var top = "MainNav-module--top--d3b44";
export var touchDisabled = "MainNav-module--touchDisabled--76aa3";