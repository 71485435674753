import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/UI';
import { useTrans } from 'utils/localisation';
import * as styles from './NoMatchingResults.module.scss';

const NoMatchingResults = ({ className, theme }) => {
  const t = useTrans(['filters']);

  return (
    <div className={cn(styles.wrapper, styles[theme], className)}>
      <Icon name="no-matching-results" className={styles.icon} />
      <p>{t('noMatchingResults')}</p>
    </div>
  );
};

export default NoMatchingResults;
