import React from 'react';
import cn from 'classnames';

import { RadioMark } from 'components/UI';

import * as styles from './RadioBtn.module.scss';

export default function RadioBtn({ name = '', className = '', checked, children, ...rest }) {
  return (
    <label className={cn(styles.radio, className)}>
      <input
        className={cn(styles.input, checked && styles.checked)}
        type="radio"
        name={name}
        checked={checked}
        {...rest}
      />
      <RadioMark className={styles.mark} checked={checked} />
      {children}
    </label>
  );
}
