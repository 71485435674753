exports.getBuildEnvironment = function () {
  return process.env.GATSBY_BUILD_ENV;
};

exports.getIsSsr = function () {
  return process.env.NODE_ENV === 'production';
};

exports.getApiUrl = function () {
  return process.env.GATSBY_API_URL || '';
};

exports.getTeamsWebhookUrl = function () {
  if (process.env.NODE_ENV === 'development') {
    return process.env.GATSBY_TEAMS_TEST_WEBHOOK_URL || '';
  }

  return process.env.GATSBY_TEAMS_WEBHOOK_URL || '';
};

exports.getAssetUrl = function () {
  return process.env.GATSBY_STATIC_URL || '';
};
