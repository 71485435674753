import React, { useMemo, useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import { upperFirst } from 'utils';
import { useClickOutside } from 'utils/hooks';

import { Icon, Tooltip } from '../index';

import * as styles from './Input.module.scss';

export const Input = ({
  field,
  theme,
  name,
  onChange,
  mask,
  prependedTooltip,
  isTextarea,
  clearValue,
  upload,
  errorsShakeHub,
  isErrorActive,
  domProps,
  className,
  focused,
  setFocused,
  icon,
  disabled,
  maxRows = 5,
  minRows = 1,
}) => {
  const [isOpenPrependedTooltop, setIsOpenPrependedTooltop] = useState(false);
  const inputRef = useRef();
  const prependedTooltipRef = useRef();
  const closeButtonRef = useRef();

  const InputTag = useMemo(() => {
    if (isTextarea) return 'textarea';
    if (mask) return InputMask;

    return 'input';
  }, [isTextarea]);

  useEffect(() => {
    errorsShakeHub.addEventListener('setFocus', onSetFocusHandler);

    return () => {
      errorsShakeHub.removeEventListener('setFocus', onSetFocusHandler);
    };
  }, [errorsShakeHub]);

  useEffect(() => {
    if (isTextarea && inputRef.current) {
      const el = inputRef.current;
      autoGrow(el);
    }
  }, [isTextarea, inputRef.current, inputRef.current?.value]);

  useClickOutside([inputRef], () => setFocused(false));
  useClickOutside([prependedTooltipRef], () => setIsOpenPrependedTooltop(false));

  const inputChangeHandler = e => {
    if (upload) onChange(e);
    else {
      onChange(e.target.value);
    }
  };

  const autoGrow = el => {
    const { lineHeight, maxHeight } = window.getComputedStyle(el);

    if (el.scrollHeight > parseFloat(maxHeight)) {
      el.classList.add(styles.scrollbarShow);
    } else {
      el.classList.remove(styles.scrollbarShow);
    }

    el.style.height = '0';
    el.style.height =
      el.scrollHeight > parseFloat(lineHeight) ? `${el.scrollHeight}px` : lineHeight;
  };

  const style = {
    '--maxRows': maxRows,
    '--minRows': minRows,
  };

  const closeButtonMouseDownHandler = e => {
    onChange('');
    setFocused(false);
    e.stopPropagation();
  };

  const inputMouseDownHandler = e => {
    setFocused(true);
  };

  const onSetFocusHandler = ({ detail }) => {
    if (detail === name) {
      inputRef.current.focus();
      setFocused(true);
    }
  };

  return (
    <>
      <label
        className={cn(
          styles.label,
          className,
          icon && styles.withIcon,
          theme && styles[`theme${upperFirst(theme)}`],
          isTextarea && styles.textAreaWrapper,
        )}
      >
        {icon && <Icon name={icon} className={styles.inputIcon} />}
        {prependedTooltip && (
          <div
            className={styles.prependedTooltip}
            onClick={() => setIsOpenPrependedTooltop(true)}
            ref={prependedTooltipRef}
          >
            <Icon name="union" className={styles.prependedIcon} />
            {isOpenPrependedTooltop && (
              <Tooltip className={styles.tooltip} text={prependedTooltip} />
            )}
          </div>
        )}
        <InputTag
          className={cn(
            isErrorActive && styles.error,
            prependedTooltip && styles.withPrependedTooltip,
          )}
          style={style}
          name={name}
          onInput={inputChangeHandler}
          onMouseDown={inputMouseDownHandler}
          disabled={disabled}
          {...(mask
            ? {
                ...mask,
                inputRef: ref => (inputRef.current = ref),
              }
            : { ref: inputRef })}
          {...field}
          {...domProps}
        />
        <span className={styles.placeholder}>{domProps.placeholder}</span>
        {clearValue && focused && (
          <div
            ref={closeButtonRef}
            className={styles.closeButton}
            onMouseDown={closeButtonMouseDownHandler}
          />
        )}
      </label>
    </>
  );
};
