import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/UI';

import * as styles from './FullscreenButton.module.scss';

export default function FullscreenButton({ className, onClick }) {
  return (
    <div className={cn(styles.wrapper, className)} onClick={onClick} role="button">
      <Icon className={styles.icon} name="open" />
    </div>
  );
}
