// extracted by mini-css-extract-plugin
export var black = "Title-module--black--54d0b";
export var fadeInAnimation = "Title-module--fadeInAnimation--2b70c";
export var h1 = "Title-module--h1--fa6c2";
export var h1Cases = "Title-module--h1Cases--a3c69";
export var h1Secondary = "Title-module--h1Secondary--fec17";
export var h2 = "Title-module--h2--ff335";
export var h3 = "Title-module--h3--cdd9d";
export var h4 = "Title-module--h4--e1461";
export var h5 = "Title-module--h5--cc9eb";
export var lg = "Title-module--lg--02295";
export var md = "Title-module--md--67aa8";
export var title = "Title-module--title--e41b8";
export var white = "Title-module--white--26e2e";