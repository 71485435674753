import { makeAutoObservable } from 'mobx';

export default class Tags {
  tags = {};

  constructor() {
    makeAutoObservable(this);
  }

  initTags = id => {
    if (!this.tags[id]) {
      this.tags[id] = [];
    }
  };

  updateTags = (id, data) => {
    this.tags[id] = data;
  };

  getTags = id => {
    return this.tags[id];
  };

  destroyTagsData = id => delete this.tags[id];
}
