import { LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY } from 'define';

const firstEntryPoint = window.localStorage.getItem(LOCAL_STORAGE_KEY);
const sessionSiteEntryPoint = window.sessionStorage.getItem(SESSION_STORAGE_KEY);

/**
 * @description Проверка наличия и добавления первой и сессионной точки входа
 * на сайт пользователя
 * @param {object} storage local/session storage
 * @param {string} entryPoint данные из storage на проверку
 * @param {string} key ключ для storage
 */
function entryPointVerification(storage, entryPoint, key) {
  if (!entryPoint) {
    storage.setItem(key, window.location.href);
  }
}

entryPointVerification(localStorage, firstEntryPoint, LOCAL_STORAGE_KEY);
entryPointVerification(sessionStorage, sessionSiteEntryPoint, SESSION_STORAGE_KEY);
