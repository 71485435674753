import { makeAutoObservable } from 'mobx';

export default class Modal {
  modalID = null;
  modalProps = {};

  constructor() {
    makeAutoObservable(this);
  }

  open = (id, modalProps) => {
    this.modalID = typeof id === 'function' ? id.name : id;
    this.modalProps = modalProps || {};
  };

  close = () => {
    this.modalID = null;
    this.modalProps = {};
  };
}
