// extracted by mini-css-extract-plugin
export var active = "CounterBox-module--active--8b3d2";
export var bigCircles = "CounterBox-module--bigCircles--09770";
export var button = "CounterBox-module--button--1d269";
export var buttonIcon = "CounterBox-module--buttonIcon--9566d";
export var counterLayout = "CounterBox-module--counterLayout--522b1";
export var fadeInAnimation = "CounterBox-module--fadeInAnimation--41a76";
export var input = "CounterBox-module--input--c4cb0";
export var minus = "CounterBox-module--minus--474f6";
export var plus = "CounterBox-module--plus--4691d";
export var rightSide = "CounterBox-module--rightSide--29a26";