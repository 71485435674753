import React from 'react';
import cn from 'classnames';

import * as styles from './RadioMark.module.scss';

function RadioMark({ size = 'md', checked, className }) {
  return (
    <span className={cn(styles.radioMark, styles[size], checked && styles.checked, className)} />
  );
}

export default RadioMark;
