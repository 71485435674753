import { makeAutoObservable } from 'mobx';

export default class Storage {
  storage = { showVideo: false };

  constructor() {
    makeAutoObservable(this);
  }

  addItem = (id, data) => {
    this.storage[id] = data;
  };

  getItem = id => {
    return this.storage[id];
  };
}
