import React from 'react';
import cn from 'classnames';

import { CheckMark } from 'components/UI';

import * as styles from './Checkbox.module.scss';

export default function Checkbox({ name = '', className = '', checked, children, ...rest }) {
  return (
    <label className={cn(styles.checkbox, className)}>
      <input className={styles.input} type="checkbox" name={name} {...rest} />
      <CheckMark className={styles.mark} checked={checked} />
      {children}
    </label>
  );
}
