import { graphql, useStaticQuery } from 'gatsby';

export function useTranslation(locale) {
  const { translation, cookieTranslation } = useStaticQuery(graphql`
    query {
      translation {
        data
      }
      cookieTranslation {
        data
      }
    }
  `);

  return { ...JSON.parse(translation.data)[locale], ...JSON.parse(cookieTranslation.data) };
}
