import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCookie } from 'utils/cookie';
import { useTranslation } from 'staticQueries/translation';
import { usaRegion } from 'define/locale';

const COOKIE_NAME = 'x-country-code';

export function getCountryCode() {
  return getCookie(COOKIE_NAME);
}

export function isRequestedFrom(countryCode = '') {
  if (Array.isArray(countryCode)) {
    return countryCode.includes(getCountryCode()?.toLowerCase());
  }

  return getCountryCode()?.toLowerCase() === countryCode?.toLowerCase();
}

export const LanguageContext = createContext();

export function useTrans(namespaces) {
  const lang = useLang();
  const translationJson = useTranslation(lang);
  const translations = Object.assign(
    {},
    ...namespaces.map(namespace => translationJson[namespace]),
  );
  return key => translations[key];
}

export function useLang() {
  return useContext(LanguageContext);
}

export function withTrans(Component, namespaces) {
  return function WithTrans(props) {
    const t = useTrans(namespaces);
    return <Component {...props} t={t} />;
  };
}

export const withLanguageContext = (Page, lang) => {
  return function WithLanguageContext(props) {
    return (
      <LanguageContext.Provider value={lang}>
        <Page {...props} />
      </LanguageContext.Provider>
    );
  };
};

export const useIsUSA = () => {
  const [isUSA, setIsUSA] = useState(false);

  useEffect(() => {
    if (isRequestedFrom(usaRegion)) {
      setIsUSA(true);
    } else {
      setIsUSA(false);
    }
  }, []);

  return isUSA;
};
