import React, { useState, useEffect } from 'react';
import { CollapseEl } from 'components/UI';
import cn from 'classnames';
import * as styles from './Accordion.module.scss';

export default function Accordion({
  id,
  title,
  children,
  theme,
  onClick,
  isOpened,
  defaultActive,
  className,
}) {
  const [activeSection, setActiveSection] = useState(false);
  const toggleListHandler = () => setActiveSection(!activeSection);

  useEffect(() => {
    if (defaultActive) {
      setActiveSection(true);
    }
  }, []);

  return (
    <section id={id} className={cn(styles.wrapper, className, theme && styles[theme])}>
      <button
        className={cn(styles.panel, (activeSection || isOpened) && styles.active)}
        onClick={onClick ?? toggleListHandler}
      >
        {title && <div className={styles.title}>{title}</div>}
      </button>
      <CollapseEl
        toggle={isOpened ?? activeSection}
        className={cn(styles.panelContentWrapper, styles.collapse)}
      >
        {children}
      </CollapseEl>
    </section>
  );
}
