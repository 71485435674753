export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH';
export const SET_WRAPPER = 'SET_WRAPPER';
export const SET_TOOLTIP = 'SET_TOOLTIP';
export const SET_BODY_POSITION = 'SET_BODY_POSITION';
export const SET_TAIL_POSITION = 'SET_TAIL_POSITION';

export default function tooltipReducer(state, action) {
  switch (action.type) {
    case SET_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: action.payload,
      };
    case SET_WRAPPER:
      return {
        ...state,
        wrapper: action.payload,
      };
    case SET_TOOLTIP:
      return {
        ...state,
        tooltip: action.payload,
      };
    case SET_BODY_POSITION:
      return {
        ...state,
        bodyPosition: action.payload,
      };
    case SET_TAIL_POSITION:
      return {
        ...state,
        tailPosition: action.payload,
      };
    default:
      return state;
  }
}
