import { makeAutoObservable } from 'mobx';
import AwesomeDesign from './awesomeDesign';
import Header from './header';

export default class UI {
  constructor() {
    this.awesomeDesign = new AwesomeDesign();
    this.header = new Header();
    makeAutoObservable(this);
  }
}
