import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/UI';

import * as styles from './CounterBox.module.scss';

function CounterBox({ className, theme, value, decrement, increment, labelId }) {
  return (
    <div className={cn(styles.counterLayout, theme && styles[theme], className)}>
      <button
        className={cn(styles.button, value && styles.active)}
        onClick={decrement}
        aria-label="Remove"
      >
        <Icon name="minus" className={cn(styles.buttonIcon, styles.minus)} />
      </button>
      <input
        type="number"
        value={value}
        className={styles.input}
        aria-labelledby={labelId}
        disabled
      />
      <button
        className={cn(styles.button, value >= 1 && value < 100 && styles.active)}
        onClick={increment}
        aria-label="Add"
      >
        <Icon name="plus" className={cn(styles.buttonIcon, styles.plus)} />
      </button>
    </div>
  );
}

export default React.memo(CounterBox);
