import React from 'react';
import cn from 'classnames';

function LazyImage({ className, alt = 'image', src, ...rest }) {
  return <img
    className={cn(className, 'lazyload')}
    data-src={src}
    alt={alt}
    {...rest}
  />;
}

export default React.memo(LazyImage);
