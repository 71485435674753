// extracted by mini-css-extract-plugin
export var closeButton = "Input-module--closeButton--dfee1";
export var error = "Input-module--error--b9de0";
export var fadeInAnimation = "Input-module--fadeInAnimation--15950";
export var inputIcon = "Input-module--inputIcon--72c50";
export var label = "Input-module--label--f2227";
export var placeholder = "Input-module--placeholder--1e5cf";
export var prependedIcon = "Input-module--prependedIcon--01038";
export var prependedTooltip = "Input-module--prependedTooltip--e8751";
export var scrollbarShow = "Input-module--scrollbarShow--357df";
export var textAreaWrapper = "Input-module--textAreaWrapper--ad4cb";
export var tooltip = "Input-module--tooltip--19054";
export var withIcon = "Input-module--withIcon--cdf3e";
export var withPrependedTooltip = "Input-module--withPrependedTooltip--c5933";