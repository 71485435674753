import { makeAutoObservable } from 'mobx';

export default class AwesomeDesign {
  state = {};

  constructor() {
    makeAutoObservable(this);
  }

  setFilter = (id, filter) => {
    this.state[id].filter = filter;
  };

  getFilter = id => {
    return this.state[id]?.filter || '';
  };

  setPage = (id, page) => {
    this.state[id].page = page;
  };

  getState = id => {
    return this.state[id] || {};
  };

  setState = (id, data) => {
    this.state[id] = data || {};
  };

  createState = (
    id,
    data = {
      filter: '',
      page: 4,
    },
  ) => {
    this.state[id] = data;
  };
}
