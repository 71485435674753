import React from 'react';
import { navigate } from 'gatsby';
import cn from 'classnames';
import { Link } from 'components/UI';
import * as styles from './Navigation.module.scss';

export default function Navigation({ className, nextLink, prevLink, linksPayload }) {
  const handleClick = e => {
    if (prevLink === 'history') {
      navigate(-1);
      e.preventDefault();
    }
  };
  return (
    <nav className={cn(styles.navigation, className)}>
      <Link
        className={cn(styles.link, styles.prev, !prevLink && styles.disabled)}
        href={prevLink !== 'history' ? prevLink : undefined}
        state={linksPayload}
        onClick={handleClick}
      >
        Prev
      </Link>
      <div className={styles.separator} />
      <Link
        className={cn(styles.link, styles.next, !nextLink && styles.disabled)}
        href={nextLink}
        state={linksPayload}
      >
        Next
      </Link>
    </nav>
  );
}
