import React from 'react';
import cn from 'classnames';
import { Icon } from '../index';
import Markup from 'components/Markup';
import ListItem from './ListItem';
import * as styles from './Alert.module.scss';

export default function Alert({
  icon = 'hint',
  className,
  content,
  markupContent,
  theme,
  background = 'gray',
  shadow,
  fontSize = 'md',
  Button,
  list,
}) {
  return (
    <aside
      className={cn(
        styles.hint,
        theme && styles[theme],
        background && styles[`${background}Bg`],
        shadow && styles.shadow,
        className,
      )}
    >
      <div className={cn(styles.content, styles[`fontSize${fontSize}`])}>
        {list ? (
          <div className={styles.list}>
            {list.map((item, index) => (
              <ListItem key={index} {...item} />
            ))}
          </div>
        ) : (
          <>
            {icon && <Icon name={icon} className={styles.icon} />}
            {content && <p className={styles.text}>{content}</p>}
            {markupContent && (
              <Markup tag="p" className={styles.text}>
                {markupContent}
              </Markup>
            )}
          </>
        )}
      </div>
      {Button && <div className={styles.buttonWrapper}>{Button}</div>}
    </aside>
  );
}
