import React, { useCallback, useState, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Icon, CheckMark, RadioMark } from 'components/UI';
import { useTrans } from 'utils/localisation';
import { upperFirst } from 'utils';
import { useClickOutside } from 'utils/hooks';

import * as styles from './Select.module.scss';

export default function SelectMultiple({
  options,
  placeholder,
  onlyPlaceholder,
  onSelect,
  selected,
  className,
  withSearch,
  type,
  theme,
}) {
  const t = useTrans(['common']);
  const selectRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  useClickOutside([selectRef], () => setIsOpen(false));

  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  const handleItemClick = clicked => {
    const result = selected.some(({ value }) => value === clicked.value)
      ? selected.filter(item => item.value !== clicked.value)
      : [...selected, clicked];
    onSelect(result);
  };

  const optionsReady = useMemo(
    () =>
      options
        .map(item => {
          if (item.value) {
            return item;
          }

          return {
            value: item,
            label: item,
          };
        })
        .filter(item => item.value.toLowerCase().includes(search.toLowerCase())),
    [options, search],
  );

  const openListHandler = e => {
    e.preventDefault();
    if (!isOpen) {
      setSearch('');
    }
    setIsOpen(prev => !prev);
  };

  return (
    <div
      className={cn(
        className,
        styles.select,
        isOpen && styles.isOpen,
        styles[`theme${upperFirst(theme)}`],
      )}
      tabIndex={0}
      ref={selectRef}
    >
      <button
        className={cn(styles.button, selected.length > 0 && styles.selected)}
        onClick={openListHandler}
      >
        {getLabel(selected, placeholder, onlyPlaceholder)}
      </button>
      <div className={cn(styles.dropdown, isOpen && styles.active)}>
        {withSearch && (
          <div className={styles.searchRow}>
            <Icon name="loupe-left-side" className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t('search')}
              className={styles.searchInput}
              value={search}
              onChange={handleSearch}
            />
          </div>
        )}
        <ul className={styles.list}>
          {optionsReady.map(item => (
            <li key={item.value}>
              <div
                role="button"
                className={styles.itemButtonMultiple}
                onClick={() => handleItemClick(item)}
              >
                {type === 'radio' ? (
                  <RadioMark
                    className={cn(styles.mark, styles.radioMark)}
                    checked={selected.some(({ value }) => value === item.value)}
                  />
                ) : (
                  <CheckMark
                    className={cn(styles.mark, styles.checkMark)}
                    checked={selected.some(({ value }) => value === item.value)}
                  />
                )}
                {item.label}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function getLabel(selected, placeholder, onlyPlaceholder) {
  if (onlyPlaceholder) {
    return onlyPlaceholder;
  }
  if (selected && selected.length === 0) {
    return placeholder;
  }
  return selected.map(item => item.label).join(', ');
}
