import React, { forwardRef } from 'react';
import cn from 'classnames';
import * as styles from './Container.module.scss';

function Container(
  { className, background = 'white', padding, theme, margin, children, id, ...props },
  ref,
) {
  return (
    <section
      ref={ref}
      className={cn(
        className,
        styles.container,
        styles[`${background}BG`],
        padding && styles[`padding${padding}`],
        margin && styles[`margin${margin}`],
        theme && styles[theme],
      )}
      id={id}
      {...props}
    >
      {children}
    </section>
  );
}

export default forwardRef(Container);
