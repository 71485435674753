export const defaultXsm = {
  cols: 1,
  gutterX: '20px',
  gutterY: '20px',
};

export const defaultSm = {
  cols: 1,
  gutterX: '20px',
  gutterY: '20px',
};

export const defaultMd = {
  cols: 2,
  gutterX: '20px',
  gutterY: '20px',
};

export const defaultLg = {
  cols: 3,
  gutterX: '20px',
  gutterY: '20px',
};

export const defaultXl = {
  cols: 3,
  gutterX: '30px',
  gutterY: '30px',
};
