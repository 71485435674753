import React from 'react';
import * as styles from './Slider.module.scss';

/**
 * @param {number} i index of pagination dot
 * @description get width for the first five pagination dots in slider
 */
export const getInitialDotsWidth = i => {
  // width of the first three dots
  if (i < 3) {
    return 'defaultWidth';
  }

  // width of the fourth dot
  if (i === 3) {
    return 'mediumWidth';
  }

  // width of the fifth dot
  if (i === 4) {
    return 'smallWidth';
  }

  // hide other dots
  return 'hidden';
};

/**
 * @param {number} i index of pagination dot
 * @param {number} current index of current slide
 * @description get width for dots in the middle of a slider. Third dot and active (fourth) dot have default width
 */

export const getDotsWidth = (i, current) => {
  // width of the second and fifth visible dots
  if (i === current + 1 || i === current - 2) {
    return 'mediumWidth';
  }

  // width of the first and last visible dots
  if (i === current + 2 || i === current - 3) {
    return 'smallWidth';
  }

  // hide other dots
  if (i >= current + 3 || i <= current - 4) {
    return 'hidden';
  }
};

/**
 * @param {number} i index of pagination dot
 * @param {number} length length of slides array - 4
 * @description get width for the last five pagination dots of the slider
 */

export const getLastDotsWidth = (i, length) => {
  // width of the last three dots
  if (i > length) {
    return 'defaultWidth';
  }

  // width of the second visible dot
  if (i === length) {
    return 'mediumWidth';
  }

  // width of the first visible dot
  if (i === length - 1) {
    return 'smallWidth';
  }

  // hide other dots
  return 'hidden';
};

export function CustomPagination(dots) {
  return <ul>{dots}</ul>;
}

/**
 * @param {number} length length of the slides array
 * @param {number} current index of current slide
 * @param {number} i index of pagination dot
 * @description return dots with custom width depending on the number of slides in the slider
 */

export function CustomDot(length, current, i) {
  // if five or less slides, then dots have default width of 30 px
  if (length <= 5) return <button>{i}</button>;

  // width of first five dots at the start of a slider
  if (current < 3) {
    const buttonWidth = getInitialDotsWidth(i);
    return <button className={styles[buttonWidth]}>{i}</button>;
  }

  // width of dots in the middle of a slider
  if (current > length - 4) {
    const buttonWidth = getLastDotsWidth(i, length - 4);

    return <button className={styles[buttonWidth]}>{i}</button>;
  }

  // width of last five dots in a slider
  if (current >= 3) {
    const buttonWidth = getDotsWidth(i, current);
    return <button className={styles[buttonWidth]}>{i}</button>;
  }

  // return dots, that are not covered by previous condition checks, with default width
  return <button>{i}</button>;
}
