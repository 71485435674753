import {
  LOCAL_STORAGE_KEY,
  SESSION_STORAGE_KEY,
  EMPLOYEES_COUNT,
  PROJECTS_COUNT,
  OFFICES_COUNT,
} from 'define';
import { modal as modalStore } from 'store';

export const lockUnlockPage = {
  lock: () => {
    document.documentElement.setAttribute('style', 'overflow: hidden;');
  },
  unlock: () => {
    document.documentElement.setAttribute('style', '');
  },
};

export const getEntryPointsFromStorages = point => {
  if (point === 'first') {
    return { firstPoint: window.localStorage.getItem(LOCAL_STORAGE_KEY) };
  }

  if (point === 'session') {
    return { sessionPoint: window.sessionStorage.getItem(SESSION_STORAGE_KEY) };
  }

  return {
    firstPoint: window.localStorage.getItem(LOCAL_STORAGE_KEY),
    sessionPoint: window.sessionStorage.getItem(SESSION_STORAGE_KEY),
  };
};

export const getReferrer = () => {
  const referrer = document.referrer;

  if (!referrer) {
    return 'direct';
  }

  return referrer;
};

export function getCDNUrl(path) {
  return `https://static.andersenlab.com/${path}`;
}

export function getYoutubeId(link) {
  const regexp = /youtu(?:.*\/v\/|.*v=|\.be\/)([A-Za-z0-9_-]{11})/;

  return link.match(regexp)[1];
}

export function distinct(arr) {
  return Object.keys(
    arr.reduce(
      (acc, item) => ({
        ...acc,
        [item.trim()]: true,
      }),
      {},
    ),
  );
}

export function injectData(keyword, data) {
  let result = keyword;

  if (/{(\w+)}/.test(result)) {
    result = result.replace(/{(\w+)}/g, data);
  }

  return result;
}

export function throttle(func, ms, withLastCall = true) {
  let isThrottled = false;
  let savedArgs = null;

  function wrapper(...args) {
    if (isThrottled) {
      savedArgs = args;
      return;
    }

    func(...args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;

      if (withLastCall && savedArgs) {
        wrapper(...savedArgs);
        savedArgs = null;
      }
    }, ms);
  }

  return wrapper;
}

export function debounce(f, ms) {
  let isReadyToExecute = false;

  return function () {
    if (isReadyToExecute) return;

    f.apply(this, arguments);

    isReadyToExecute = true;

    setTimeout(() => (isReadyToExecute = false), ms);
  };
}

export function replaceKeyword(str, specialKey, data) {
  if (typeof str !== 'string') return str;

  const matches = str.matchAll(/{(\w+)}/g);
  let result = str;

  for (const match of matches) {
    if (specialKey && match[1] === specialKey) {
      result = result.replace(match[0], data);
    } else {
      result = result.replace(match[0], checkKeyword);
    }
  }
  return result;
}

function checkKeyword(match) {
  const companyAge = new Date().getFullYear() - new Date(2007, 1, 1).getFullYear();

  switch (match) {
    case '{age}':
      return companyAge;
    case '{employees}':
      return EMPLOYEES_COUNT;
    case '{projects}':
      return PROJECTS_COUNT;
    case '{offices}':
      return OFFICES_COUNT;
    default:
      return match;
  }
}

export function upperFirst(str) {
  const result = str;
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isEmpty(value) {
  return (
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function isStaticLink(link) {
  return link?.includes('static.andersenlab.com') ?? false;
}

export function handleGLobalLinksInText(textRef) {
  const links = textRef.current?.querySelectorAll('a');

  const handleClick = href => e => {
    e.preventDefault();
    modalStore.open('LangNotificationModal', { href });
  };

  links.forEach(element => {
    if (element.href.startsWith('https://andersenlab.com')) {
      element.onclick = handleClick(element.href);
    }
  });
}
