import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { CloseButton } from '../index';
import { ScrollContext } from 'utils/scroll';
import { useTooltip } from './hooks';
import * as styles from './Tooltip.module.scss';

export default function Tooltip({
  isOpen,
  closeHandler,
  closeOnScroll,
  placement = 'center', // ещё есть варианты 'start', 'end', 'bottom-start-stick'
  tailPlacement = 'center', // позиционирование хвостика тултипа, ещё есть варианты 'none', 'start', 'end' и 'auto'
  text,
  node,
  insideModal = false,
  className,
  wrapperClassName,
  textClassName,
  children,
  closeButton = false,
  theme,
}) {
  const { wrapperRef, tooltipRef, bodyPosition, tailPosition } = useTooltip(
    isOpen,
    placement,
    tailPlacement,
    closeOnScroll,
    closeHandler,
    insideModal,
  );

  return (
    <div
      ref={wrapperRef}
      className={cn(
        styles.wrapper,
        wrapperClassName,
        insideModal && styles.insideModal,
        theme && styles[theme],
      )}
    >
      {children}
      {isOpen &&
        ReactDOM.createPortal(
          <ScrollContext.Provider value={tooltipRef.current}>
            <>
              <div
                id="tooltip"
                ref={tooltipRef}
                className={cn(
                  styles.tooltip,
                  className,
                  tailPlacement !== 'auto' && styles.manualTailPosition,
                  tailPlacement !== 'auto' && styles[`tail_${tailPlacement}`],
                  insideModal && styles.insideModal,
                  theme && styles[theme],
                )}
                style={{ ...bodyPosition }}
              >
                {closeButton && <CloseButton onClose={closeHandler} size="sm" />}
                {text && <div className={cn(styles.text, textClassName)}>{text}</div>}
                {node}
              </div>
              {tailPlacement === 'auto' && (
                <span
                  className={cn(styles.tail, insideModal && styles.insideModal)}
                  style={{ ...tailPosition }}
                />
              )}
            </>
          </ScrollContext.Provider>,
          document.getElementById(insideModal ? 'modal' : '___gatsby'),
        )}
    </div>
  );
}
