import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useTrans } from 'utils/localisation';

import * as styles from './PreviewCardsList.module.scss';

export default function PreviewCardsList({
  className,
  pageSize,
  CaseComponent,
  items,
  buttonColor = 'black',
  buttonText,
  ...props
}) {
  const [count, setCount] = useState(pageSize);
  const t = useTrans(['common']);

  useEffect(() => {
    setCount(pageSize);
  }, [items]);

  const showMore = useCallback(() => {
    setCount(prev => prev + pageSize);
  }, []);

  return (
    <div className={className}>
      {items?.slice(0, count).map((item, i) => (
        <CaseComponent key={item.id || `list-item-${i}`} {...item} {...props} />
      ))}
      {count < items?.length && (
        <div className={styles.showRow}>
          <button onClick={showMore} className={cn(styles.seeMore, styles[buttonColor])}>
            {`${buttonText || t('seeMore')} (${items?.length - count})`}
          </button>
        </div>
      )}
    </div>
  );
}
