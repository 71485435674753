import { graphql, useStaticQuery } from 'gatsby';

export function useOfficesInfo() {
  const {
    allOffice: { nodes: offices },
  } = useStaticQuery(graphql`
    query {
      allOffice {
        nodes {
          id: officeId
          city
          country
          coordinates
          address
          phone
          images {
            url
            caption
          }
          hideOnZoomOut
          latOffset
        }
      }
    }
  `);

  return offices;
}
