// extracted by mini-css-extract-plugin
export var big = "Loader-module--big--84ffe";
export var block = "Loader-module--block--46b38";
export var fadeInAnimation = "Loader-module--fadeInAnimation--9bfc2";
export var formField = "Loader-module--formField--d101f";
export var fullHeight = "Loader-module--fullHeight--2b8ac";
export var item = "Loader-module--item--67d8b";
export var ldsRing = "Loader-module--lds-ring--c8434";
export var loader = "Loader-module--loader--3f657";
export var page = "Loader-module--page--dcc8d";
export var wrap = "Loader-module--wrap--63cbb";