import React, { useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'utils';
import * as styles from './Image.module.scss';

function Image({ className, classImage, alt = 'image', src = {}, lazy = true, ...props }) {
  const srcPointers = useMemo(
    () => [
      [src.mobile_webp, src.mobile_webp_2x, src.mobile, src.mobile_2x, '544'],
      [src.tablet_webp, src.tablet_webp_2x, src.tablet, src.tablet_2x, '1024'],
      [src.desktop_webp, src.desktop_webp_2x, src.desktop, src.desktop_2x],
    ],
    [src],
  );

  return !isEmpty(src) ? (
    <picture className={className} {...props}>
      {srcPointers.map((img, i) => (
        <React.Fragment key={i}>
          {img[0] && (
            <source {...getSrcSet(lazy, img[0], img[1])} {...getMedia(img[4])} type="image/webp" />
          )}
          {img[2] && <source {...getSrcSet(lazy, img[2], img[3])} {...getMedia(img[4])} />}
        </React.Fragment>
      ))}
      <img
        className={cn(classImage, styles.img, lazy && 'lazyload')}
        alt={alt}
        {...getSrc(lazy, src.desktop)}
      />
    </picture>
  ) : null;
}

function getSrcSet(lazy, src, src2x) {
  if (lazy) {
    return {
      'data-srcset': `${src} 1x${src2x ? `, ${src2x} 2x` : ''}`,
    };
  }

  return {
    srcSet: `${src} 1x${src2x ? `, ${src2x} 2x` : ''}`,
  };
}

function getSrc(lazy, src) {
  if (lazy) {
    return {
      'data-src': src,
    };
  }

  return { src };
}

function getMedia(viewport) {
  if (viewport) {
    return {
      media: `(max-width: ${viewport}px)`,
    };
  }
}

export default React.memo(Image);
