import React from 'react';
import cn from 'classnames';
import Markup from 'components/Markup';
import { replaceKeyword } from 'utils';

import * as styles from './List.module.scss';

const ListItem = ({ item, itemTag }) => (
  <>
    {itemTag ? (
      <Markup tag={itemTag} className={styles.itemWrapper}>
        {replaceKeyword(item)}
      </Markup>
    ) : (
      replaceKeyword(item)
    )}
  </>
);

export default function List({
  listType = 'unordered',
  items = [],
  theme,
  size = 'md',
  marker = 'checked',
  className,
  itemTag,
}) {
  const ListWrapper = listType === 'ordered' ? 'ol' : 'ul';
  const preparedMarker = listType === 'ordered' ? 'number' : marker;

  return (
    <ListWrapper
      className={cn(
        styles.list,
        preparedMarker && styles[preparedMarker],
        theme && styles[theme],
        className,
      )}
    >
      {items.map(item => (
        <li
          key={item?.key ?? item?.sublist ?? item?.name ?? item}
          className={cn(styles.item, styles[size])}
        >
          <ListItem item={item?.sublist ? item?.name : item} itemTag={itemTag} />
          {item?.sublist && (
            <ul>
              {item?.sublist.map(item => (
                <li key={item} className={styles.subItem}>
                  <ListItem item={item} itemTag={itemTag} />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ListWrapper>
  );
}
