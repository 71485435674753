import React from 'react';

export default function Video({ className, poster, source, ...props }) {
  return (
    <video className={className} poster={poster} {...props}>
      {source?.webm && <source src={source.webm} type="video/webm" />}
      <source src={source.mp4} type="video/mp4" />
    </video>
  );
}
