import Modal from './modal';
import Storage from './storage';
import RelatedCasesStore from './relatedCases';
import Calculator from './calculator';
import Cookies from './cookies';
import InsightsShorts from './insightsShorts';
import StepsCalculator from './pricingCalculator';
import Tags from './tags';
import UI from './ui';

export const cookies = new Cookies();
export const modal = new Modal();
export const tagsStore = new Tags();
export const storage = new Storage();
export const insightsShorts = new InsightsShorts();
export const pricingCalculator = new StepsCalculator();
export const relatedCasesStore = new RelatedCasesStore();
export const calculatorState = new Calculator();
export const ui = new UI();

export { observer } from 'mobx-react-lite';
