import React from 'react';
import cn from 'classnames';
import * as styles from './subtitle.module.scss';

export default function Subtitle({ className, children, size = 'md', tag = 'p', color = 'black' }) {
  const Wrapper = tag;
  return (
    <Wrapper className={cn(styles.subtitle, styles[size], styles[color], className)}>
      {children}
    </Wrapper>
  );
}
