import React from 'react';
import { Link as LinkOriginal } from 'gatsby';
import { DOMAIN_URL, DOMAIN_URL_DE } from 'define';

function Link({ className, href = '', children, onClick, state, target, ...other }) {
  if (
    (href.startsWith('https://') || href.startsWith('http://')) &&
    !(href.startsWith(DOMAIN_URL) || href.startsWith(DOMAIN_URL_DE))
  ) {
    return (
      <a
        className={className}
        onClick={onClick}
        href={href}
        target="_blank"
        rel="noreferrer noopener noindex nofollow"
        {...other}
      >
        {children}
      </a>
    );
  }

  if (href.startsWith('https://') || target) {
    return (
      <a className={className} onClick={onClick} href={href} target={target} {...other}>
        {children}
      </a>
    );
  }

  return (
    <LinkOriginal onClick={onClick} className={className} to={href} state={state} {...other}>
      {children}
    </LinkOriginal>
  );
}

export default React.memo(Link);
