import { globalHistory } from '@reach/router';
import { useEffect } from 'react';

export function useHistoryCallback(callback) {
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        callback();
      }
    });
  }, []);
}
