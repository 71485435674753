import React, { useState, useEffect } from 'react';

import { ScrollContext } from 'utils/scroll';
import { ABTestContext } from 'utils/abTest';
import { LanguageContext } from 'utils/localisation';
import { getCookie } from 'utils/cookie';

function RootWrapper({ element }) {
  const lang = process.env.GATSBY_LANG || 'en';
  const root = typeof window !== 'undefined' ? window : null;
  const [activeTests, setActiveTests] = useState(null);

  useEffect(() => {
    const tests = JSON.parse(getCookie('x-ab-tests'))?.experiments;

    if (tests) {
      setActiveTests(tests);
    }
  }, []);

  return (
    <ScrollContext.Provider value={root}>
      <ABTestContext.Provider value={activeTests}>
        <LanguageContext.Provider value={lang}>{element}</LanguageContext.Provider>
      </ABTestContext.Provider>
    </ScrollContext.Provider>
  );
}

export default RootWrapper;
