import React, { forwardRef } from 'react';

function Markup({ tag = 'div', className, children, ...props }, ref) {
  const Wrapper = tag;

  return (
    <Wrapper
      ref={ref}
      className={className}
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    />
  );
}

export default forwardRef(Markup);
