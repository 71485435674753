import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { CollapseEl, Title } from 'components/UI';
import * as styles from './ExpandPanel.module.scss';

export default function ExpandPanel({ title, titleTag = 'h3', children, isOpened = false, theme }) {
  const [state, setState] = useState({ collapse: isOpened });
  const toggleListHandler = useCallback(
    () => setState(prev => ({ ...prev, collapse: !prev.collapse })),
    [],
  );

  return (
    <>
      <button
        className={cn(styles.panel, state.collapse && styles.active, theme && styles[theme])}
        onClick={toggleListHandler}
      >
        <Title tag={titleTag} className={styles.title}>
          {title}
        </Title>
      </button>
      <CollapseEl toggle={state.collapse}>{children}</CollapseEl>
    </>
  );
}
