import React, { useEffect, useRef, useState, useCallback } from 'react';
import cn from 'classnames';
import { LazyImage } from 'components/UI';
import { createTabID, createPanelID } from 'utils/tabs';
import { useHorizontalScroll, useWindowResize } from 'utils/hooks';
import { useLang } from 'utils/localisation';

import * as styles from './Tabs.module.scss';

function Tabs({
  filters,
  active,
  setActive,
  setStyles,
  setActiveTabNumber,
  className,
  tabsAriaLabel,
  theme,
}) {
  const lang = useLang();
  const tabRef = useRef([]);
  const { scrollRef, scrollLeft, setScrollLeft } = useHorizontalScroll();
  const { width } = useWindowResize();

  const [initial, setInitial] = useState(true);
  const [current, setCurrent] = useState(active);
  const [inTransition, setInTransition] = useState(false);
  const [lineStyles, setLineStyles] = useState({});
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const scrollContainer = scrollRef?.current;
  const isDesktop = width > 1024;
  const showScrollButtons =
    isDesktop && scrollContainer?.clientWidth !== scrollContainer?.scrollWidth;

  const handleResizeWindow = useCallback(() => {
    setLineStyles({
      '--width': `${tabRef.current[active].clientWidth}px`,
      '--left': `${tabRef.current[active].offsetLeft}px`,
    });
    checkForScrollPosition();
  }, [active, tabRef.current, lineStyles]);

  const checkForScrollPosition = () => {
    if (scrollContainer) {
      const { scrollWidth, clientWidth } = scrollContainer;

      if (lang === 'ae') {
        setCanScrollLeft(-scrollLeft < scrollWidth - clientWidth);
        setCanScrollRight(scrollLeft < 0);
      } else {
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
      }
    }
  };

  const scrollTo = newScrollPosition => {
    setScrollLeft(newScrollPosition);
    scrollContainer.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  };

  const scrollToVisible = () => {
    if (scrollContainer) {
      const tabRightPosition =
        tabRef.current[current].offsetLeft + tabRef.current[current].clientWidth;
      const containerRightPosition = scrollContainer.scrollLeft + scrollContainer.clientWidth;

      if (tabRightPosition > containerRightPosition) {
        const newScrollPosition =
          scrollContainer.scrollLeft + (tabRightPosition - containerRightPosition);
        scrollTo(newScrollPosition);
      }
      if (scrollContainer.scrollLeft > tabRef.current[current].offsetLeft) {
        const newScrollPosition =
          scrollContainer.scrollLeft +
          (tabRef.current[current].offsetLeft - scrollContainer.scrollLeft);
        scrollTo(newScrollPosition);
      }
    }
  };

  const handleScroll = direction => {
    if (scrollContainer) {
      const newScrollPosition = scrollContainer.scrollLeft + (direction === 'left' ? -200 : 200);

      scrollTo(newScrollPosition);
    }
  };

  useEffect(() => {
    if (initial) {
      setCurrent(active);
      setTimeout(handleResizeWindow, 200);
    }
  }, [active, initial]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [active]);

  useEffect(() => {
    if (showScrollButtons) {
      checkForScrollPosition();
    }
  }, [scrollLeft, scrollContainer, showScrollButtons]);

  const handleChange = (item, index) => () => {
    setInTransition(true);

    if (typeof setActiveTabNumber === 'function') {
      setActiveTabNumber(index + 1);
    }
    setCurrent(item);
    setLineStyles({
      '--width': `${tabRef.current[item].clientWidth}px`,
      '--left': `${tabRef.current[item].offsetLeft}px`,
    });
    setStyles({ '--opacity': 0.1 });
  };

  const onTransitionEnd = useCallback(
    e => {
      if (e.propertyName === 'left' && inTransition) {
        setInTransition(false);
      }
      if (initial) {
        setInitial(false);
      }
      setActive(current);
      setStyles({ '--opacity': 1 });
      scrollToVisible();
    },
    [initial, inTransition, current],
  );

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => handleScroll('left')}
        className={styles.scrollButtonLeft}
        disabled={!canScrollLeft}
        hidden={!showScrollButtons}
        aria-label="Scroll left"
      />
      <div className={cn(styles.buttonRow, theme && styles[theme], className)} ref={scrollRef}>
        <div className={styles.buttons} role="tablist" aria-label={tabsAriaLabel}>
          {filters.map(({ id, tabLabel, tabLogo }, i) => {
            const isCurrentTab = current === id;

            return (
              <button
                key={id}
                ref={el => (tabRef.current[id] = el)}
                className={cn(
                  styles.button,
                  tabLogo && styles.withLogo,
                  isCurrentTab && styles.currentTab,
                )}
                onClick={handleChange(id, i)}
                role="tab"
                aria-selected={isCurrentTab}
                aria-controls={createPanelID(tabsAriaLabel, i)}
                id={createTabID(tabsAriaLabel, i)}
                tabIndex={isCurrentTab ? 0 : -1}
                disabled={isCurrentTab || inTransition}
              >
                {tabLabel && <span>{tabLabel.toUpperCase()}</span>}
                {tabLogo && <LazyImage className={styles.logo} src={tabLogo} alt="Company logo" />}
              </button>
            );
          })}
        </div>
        <div className={styles.line} style={lineStyles} onTransitionEnd={onTransitionEnd} />
      </div>
      <button
        onClick={() => handleScroll('right')}
        className={styles.scrollButtonRight}
        disabled={!canScrollRight}
        hidden={!showScrollButtons}
        aria-label="Scroll Right"
      />
    </div>
  );
}

export default React.memo(Tabs);
