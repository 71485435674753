import { graphql, useStaticQuery } from 'gatsby';

export function usePhoneNumbers() {
  const {
    allPhoneNumbers: { nodes: phoneNumbers },
  } = useStaticQuery(graphql`
    query {
      allPhoneNumbers {
        nodes {
          isDefault
          phone
          countries
        }
      }
    }
  `);

  return phoneNumbers;
}
