// ComposeLeftContent is used to add block/page descriptions into Header
export const composeLeftContent = item => {
  const { title, description, button } = item;

  return {
    title,
    description,
    button,
  };
};
