import React, { useState, useCallback, useRef } from 'react';
import cn from 'classnames';
import { Icon } from '../index';
import { useTrans } from 'utils/localisation';
import { useClickOutside } from 'utils/hooks';

import * as styles from './Select.module.scss';

export default function Select({
  className,
  placeholder,
  items,
  onItemClick,
  reverse,
  withSort = true,
  withSearch,
  BeforeList,
}) {
  const selectRef = useRef();
  const t = useTrans(['common']);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState('');

  useClickOutside([selectRef], () => setActive(false));

  const openListHandler = e => {
    e.preventDefault();
    if (active && search) {
      setTimeout(() => setSearch(''), 250);
    }
    setActive(prev => !prev);
  };

  const clickItemHandler = (e, type) => {
    e.preventDefault();
    onItemClick(type);
    openListHandler(e);
  };

  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  const renderItems = useCallback(() => {
    if (Array.isArray(items)) return renderArray(items);
    return Object.keys(items).map(key => {
      if (
        items[key]?.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())).length
      ) {
        return (
          <React.Fragment key={key}>
            <p className={cn(styles.itemButton, styles.bold)}>{key}</p>
            {renderArray(items[key])}
          </React.Fragment>
        );
      } else return null;
    });
  }, [items, search]);

  const renderArray = useCallback(
    items => {
      let result = items?.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

      if (withSort) {
        result = result?.sort((a, b) => a.name?.localeCompare(b.name));
      }

      return result?.map(({ type, name }) => (
        <li key={type}>
          <button className={styles.itemButton} onClick={e => clickItemHandler(e, type)}>
            {name}
          </button>
        </li>
      ));
    },
    [items, search, withSort],
  );

  return (
    <div className={cn(className, styles.select, active && styles.isOpen)} ref={selectRef}>
      <button className={cn(styles.button, active && styles.selected)} onClick={openListHandler}>
        {placeholder}
      </button>
      <div className={cn(styles.dropdown, active && styles.active, reverse && styles.reverse)}>
        {withSearch && (
          <div className={styles.searchRow}>
            <Icon name="loupe-left-side" className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t('search')}
              className={styles.searchInput}
              value={search}
              onChange={handleSearch}
            />
          </div>
        )}
        {BeforeList}
        <ul className={styles.list}>{renderItems(items)}</ul>
      </div>
    </div>
  );
}
