import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import { lockUnlockPage } from 'utils';
import { CollapseEl, Link } from 'components/UI';
import * as styles from './MobileNav.module.scss';

function renderLink(link, current, mod) {
  const key = Object.keys(link)[0];

  return (
    <Link
      key={key}
      className={cn(
        styles.item,
        styles.hidden,
        current === link[key] && styles.current,
        styles[mod],
      )}
      href={link[key]}
    >
      {key}
    </Link>
  );
}

function Links({ links, current }) {
  const [activeTab, setActiveTab] = useState();

  if (links.find(Array.isArray)) {
    const firstLink = links[0];
    const key = Object.keys(firstLink)[0];

    return (
      <div className={styles.dropdownWrapper}>
        <div
          className={cn(styles.item, styles.subItem, activeTab && styles.active)}
          onClick={() => setActiveTab(!activeTab)}
        >
          <Link
            className={cn(current === firstLink[key] && styles.current, styles.collapseName)}
            href={firstLink[key]}
          >
            {key}
          </Link>
          <div className={cn(styles.arrowRotated, activeTab && styles.arrowRotatedActive)} />
        </div>
        <CollapseEl toggle={activeTab} className={styles.subCollapse}>
          <div className={styles.sublist}>
            {links[1].map(link => renderLink(link, current, 'sublist'))}
          </div>
        </CollapseEl>
      </div>
    );
  }

  return links.sort((a, b) => a.priority - b.priority).map(link => renderLink(link, current));
}

const MemoLinks = React.memo(Links);

export default function MobileNav({ links }) {
  const current = useLocation().pathname;
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    lockUnlockPage.lock();
    return () => lockUnlockPage.unlock();
  }, []);

  const handleClick = useCallback(
    (e, key) => {
      e.stopPropagation();
      activeTab === key ? setActiveTab() : setActiveTab(key);
    },
    [activeTab],
  );

  return (
    <nav className={styles.mobileNav}>
      {links.map(item => {
        const isActive = activeTab === item.key;
        const includesSubArray = Array.isArray(item.submenu?.[0][1]);
        let submenuItems = item.submenu;

        if (item.submenu && !includesSubArray) {
          submenuItems = item.submenu.flat();
        }

        return (
          <div className={styles.dropdownWrapper} key={item.key}>
            {item.submenu ? (
              <>
                <div
                  className={cn(styles.item, isActive && styles.active)}
                  onClick={e => handleClick(e, item.key)}
                >
                  {item?.to ? (
                    <Link href={item.to} className={styles.collapseName}>
                      {item.key}
                    </Link>
                  ) : (
                    item.key
                  )}
                  <div className={cn(styles.arrowRotated, isActive && styles.arrowRotatedActive)} />
                </div>
                <CollapseEl toggle={isActive} className={styles.collapse}>
                  <div className={cn(styles.dropdownList)}>
                    <div className={styles.layout}>
                      {includesSubArray ? (
                        submenuItems.map((items, i) => (
                          <MemoLinks key={i} links={items} current={current} />
                        ))
                      ) : (
                        <MemoLinks links={submenuItems} current={current} />
                      )}
                    </div>
                  </div>
                </CollapseEl>
              </>
            ) : item.to ? (
              <Link
                className={cn(styles.item, current === item.to && styles.current)}
                href={item.to}
              >
                {item.title}
              </Link>
            ) : null}
          </div>
        );
      })}
    </nav>
  );
}
