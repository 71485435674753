// extracted by mini-css-extract-plugin
export var buttonWrapper = "Alert-module--buttonWrapper--ace0c";
export var content = "Alert-module--content--1d5b6";
export var fadeInAnimation = "Alert-module--fadeInAnimation--23116";
export var fontSizelg = "Alert-module--fontSizelg--da0a5";
export var fontSizemd = "Alert-module--fontSizemd--36525";
export var galleryBg = "Alert-module--galleryBg--a0890";
export var grayBg = "Alert-module--grayBg--45600";
export var hint = "Alert-module--hint--77f74";
export var icon = "Alert-module--icon--ecd8b";
export var large = "Alert-module--large--5a129";
export var largeWithText = "Alert-module--largeWithText--cf872";
export var list = "Alert-module--list--9bf8b";
export var shadow = "Alert-module--shadow--fae3c";
export var text = "Alert-module--text--340fb";
export var whiteBg = "Alert-module--whiteBg--665ac";