import React from 'react';
import cn from 'classnames';
import * as styles from './Loader.module.scss';

export default function Loader({ theme }) {
  return (
    <div className={cn(styles.wrap, theme && styles[theme])}>
      <div className={styles.loader}>
        <div className={styles.item}></div>
        <div className={styles.item}></div>
        <div className={styles.item}></div>
        <div className={styles.item}></div>
      </div>
    </div>
  );
}
