import React from 'react';
import * as styles from './OfficeCard.module.scss';

export default function OfficeCard({ city, address, phone }) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{city}</p>
      <p className={styles.description}>{address}</p>
      {phone && (
        <a className={styles.link} href={`tel:${phone}`}>
          {phone}
        </a>
      )}
    </div>
  );
}
