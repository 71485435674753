import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { isRequestedFrom } from 'utils/localisation';
import { cisRegion, extendedUsaRegion, ruRegion } from 'define/locale';
import { Link } from 'components/UI';

import * as styles from './SubMenu.module.scss';

const SubmenuLink = ({ link, mod, currentLink, itemKey }) => {
  const [hideLink, setHideLink] = useState(false);
  const key = Object.keys(link)[0];
  const isActive = currentLink?.includes(link[key]);

  const cisRegionWithoutUkraine = cisRegion.filter(region => region !== 'ua');

  useEffect(() => {
    if (link.region === 'cis' && !isRequestedFrom(cisRegionWithoutUkraine)) {
      setHideLink(true);
    }
    if (link.region === 'ru' && !isRequestedFrom(ruRegion)) {
      setHideLink(true);
    }
    if (link.hideForRegion === 'usa' && isRequestedFrom(extendedUsaRegion)) {
      setHideLink(true);
    }
    if (link.hideForRegion === 'ru' && isRequestedFrom(ruRegion)) {
      setHideLink(true);
    }
  }, []);

  if (hideLink) return null;

  return (
    <span className={cn(styles.wrapLink, styles[mod])}>
      <Link href={link[key]} className={cn(styles.link, isActive && styles.active)}>
        {key}
      </Link>
    </span>
  );
};

export default SubmenuLink;
