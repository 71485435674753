import React, { lazy, Suspense } from 'react';
import cn from 'classnames';
import * as styles from './Icon.module.scss';

function Icon({ name, className, ...props }) {
  const SvgElement = lazy(() => import(`svg/${name}.svg`));

  return (
    SvgElement && (
      <Suspense fallback={null}>
        <SvgElement className={cn(className, styles.icon)} {...props} />
      </Suspense>
    )
  );
}
export default React.memo(Icon);
