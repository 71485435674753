import React from 'react';

export default function ProgressIcon({ value, max }) {
  const valueNumber = Number(value.replace('%', ''));
  const maxNumber = Number(max.replace('%', ''));
  const progressValue =
    isNaN(valueNumber) || isNaN(maxNumber) ? 100 : (valueNumber / maxNumber) * 100;

  return (
    <svg
      width="108"
      height="108"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 108"
      style={{
        display: 'block',
        minWidth: '100px',
        minHeight: '100px',
        transform: 'rotate(90deg) scaleX(-1)',
      }}
    >
      <circle
        r="50"
        cy="54"
        cx="54"
        style={{
          strokeWidth: '1',
          stroke: '#e3e3e3',
        }}
        fill="transparent"
      ></circle>
      <circle
        r="50"
        fill="transparent"
        cy="54"
        cx="54"
        style={{
          strokeWidth: '2',
          stroke: '#ffdb00',
          strokeDasharray: 314.159,
          strokeDashoffset: `${2 * 3.14 * 50 * ((100 + progressValue) / 100)}`,
        }}
      ></circle>
      <line
        x1="100"
        y1="54"
        x2="108"
        y2="54"
        style={{ strokeWidth: '1', stroke: '#e3e3e3' }}
        fill="none"
      ></line>
      <g
        style={{
          transform: `rotate(${360 * (-progressValue / 100)}deg)`,
          transformOrigin: 'center center',
        }}
        fill="transparent"
      >
        <circle r="4" cx="104" cy="54" fill="#ffdb00"></circle>
        <circle r="50" cy="54" cx="54" fill="transparent"></circle>
      </g>
    </svg>
  );
}
