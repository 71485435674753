import { makeAutoObservable } from 'mobx';

export default class RelatedCasesStore {
  relatedCases = [];

  constructor() {
    makeAutoObservable(this);
  }

  setItems = data => {
    this.relatedCases = [...data];
  };

  getItems = () => {
    return this.relatedCases;
  };
}
